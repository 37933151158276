"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GAME_MODULES = void 0;
exports.GAME_MODULES = [
    'base',
    'corpera',
    'promo',
    'venus',
    'colonies',
    'prelude',
    'turmoil',
    'community',
    'ares',
    'moon',
    'pathfinders',
    'ceo',
];
