"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'agenda',
    props: {
        type: {
            type: String,
        },
        id: {
            type: String,
        },
    },
    computed: {
        getClass() {
            switch (this.type) {
                case 'dominant-bonus':
                    return 'dominant-party-bonus';
                case 'party-bonus':
                    return 'party-bonus';
            }
            return '';
        },
    },
});
