"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants_1 = require("@/common/constants");
const Resources_1 = require("@/common/Resources");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'PlayerResource',
    props: {
        type: {
            type: String,
        },
        count: {
            type: Number,
        },
        production: {
            type: Number,
        },
        resourceProtection: {
            type: String,
            required: false,
            default: 'off',
        },
        productionProtection: {
            type: String,
            default: 'off',
        },
        value: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
    methods: {
        showResourceValue() {
            const learnerModeOn = (0, PreferencesManager_1.getPreferences)().learner_mode;
            switch (this.type) {
                case Resources_1.Resources.STEEL:
                    return learnerModeOn || this.value > constants_1.DEFAULT_STEEL_VALUE;
                case Resources_1.Resources.TITANIUM:
                    return learnerModeOn || this.value > constants_1.DEFAULT_TITANIUM_VALUE;
                case Resources_1.Resources.HEAT:
                    return this.value > 0;
                default:
                    return false;
            }
        },
    },
    computed: {
        mainCSS() {
            return 'resource_item--' + this.type;
        },
        iconCSS() {
            return 'resource_icon--' + this.type;
        },
        productionSign() {
            if (this.production > 0)
                return '+';
            return '';
        },
        protectionIcon() {
            if (this.resourceProtection === 'on') {
                return 'shield_icon';
            }
            if (this.resourceProtection === 'half') {
                return 'shield_icon_half';
            }
            if (this.productionProtection === 'on') {
                return 'shield_icon';
            }
            return '';
        },
        showProductionProtectedIcon() {
            return this.productionProtection === 'on';
        },
        showResourceProtectionIcon() {
            return this.productionProtection === 'on' && this.resourceProtection !== 'off';
        },
    },
});
