"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveCardsSortingOrder = exports.sortActiveCards = void 0;
const CardName_1 = require("@/common/cards/CardName");
function sortActiveCards(inCards) {
    const firstCardIndex = -1;
    return inCards.slice().sort(function (cardA, cardB) {
        return (exports.ActiveCardsSortingOrder.get(cardA.name) || firstCardIndex) - (exports.ActiveCardsSortingOrder.get(cardB.name) || firstCardIndex);
    });
}
exports.sortActiveCards = sortActiveCards;
exports.ActiveCardsSortingOrder = new Map([
    CardName_1.CardName.EARTH_CATAPULT,
    CardName_1.CardName.ANTI_GRAVITY_TECHNOLOGY,
    CardName_1.CardName.SKY_DOCKS,
    CardName_1.CardName.RESEARCH_OUTPOST,
    CardName_1.CardName.WARP_DRIVE,
    CardName_1.CardName.MASS_CONVERTER,
    CardName_1.CardName.QUANTUM_EXTRACTOR,
    CardName_1.CardName.SPACE_STATION,
    CardName_1.CardName.SHUTTLES,
    CardName_1.CardName.CUTTING_EDGE_TECHNOLOGY,
    CardName_1.CardName.EARTH_OFFICE,
    CardName_1.CardName.VENUS_WAYSTATION,
    CardName_1.CardName.MEDIA_GROUP,
    CardName_1.CardName.OPTIMAL_AEROBRAKING,
    CardName_1.CardName.STANDARD_TECHNOLOGY,
    CardName_1.CardName.ROVER_CONSTRUCTION,
    CardName_1.CardName.GMO_CONTRACT,
    CardName_1.CardName.MEAT_INDUSTRY,
    CardName_1.CardName.TOPSOIL_CONTRACT,
    CardName_1.CardName.TRADING_COLONY,
    CardName_1.CardName.TRADE_ENVOYS,
    CardName_1.CardName.CRYO_SLEEP,
    CardName_1.CardName.RIM_FREIGHTERS,
    CardName_1.CardName.ELECTRO_CATAPULT,
    CardName_1.CardName.SPACE_ELEVATOR,
    CardName_1.CardName.ENERGY_MARKET,
    CardName_1.CardName.ORBITAL_CLEANUP,
    CardName_1.CardName.MARTIAN_ZOO,
    CardName_1.CardName.MARTIAN_RAILS,
    CardName_1.CardName.POWER_INFRASTRUCTURE,
    CardName_1.CardName.MELTWORKS,
    CardName_1.CardName.AI_CENTRAL,
    CardName_1.CardName.SUB_CRUST_MEASUREMENTS,
    CardName_1.CardName.RESTRICTED_AREA,
    CardName_1.CardName.RESTRICTED_AREA_ARES,
    CardName_1.CardName.DEVELOPMENT_CENTER,
    CardName_1.CardName.HI_TECH_LAB,
    CardName_1.CardName.RED_SPOT_OBSERVATORY,
    CardName_1.CardName.AERIAL_MAPPERS,
    CardName_1.CardName.INVENTORS_GUILD,
    CardName_1.CardName.BUSINESS_NETWORK,
    CardName_1.CardName.MARS_UNIVERSITY,
    CardName_1.CardName.OLYMPUS_CONFERENCE,
    CardName_1.CardName.SPINOFF_DEPARTMENT,
    CardName_1.CardName.ATMO_COLLECTORS,
    CardName_1.CardName.DEUTERIUM_EXPORT,
    CardName_1.CardName.FLOATING_HABS,
    CardName_1.CardName.DIRIGIBLES,
    CardName_1.CardName.FLOATER_TECHNOLOGY,
    CardName_1.CardName.STRATOPOLIS,
    CardName_1.CardName.EXTRACTOR_BALLOONS,
    CardName_1.CardName.FORCED_PRECIPITATION,
    CardName_1.CardName.JET_STREAM_MICROSCRAPPERS,
    CardName_1.CardName.LOCAL_SHADING,
    CardName_1.CardName.TITAN_SHUTTLES,
    CardName_1.CardName.JOVIAN_LANTERNS,
    CardName_1.CardName.TITAN_AIRSCRAPPING,
    CardName_1.CardName.TITAN_FLOATING_LAUNCHPAD,
    CardName_1.CardName.JUPITER_FLOATING_STATION,
    CardName_1.CardName.SATURN_SURFING,
    CardName_1.CardName.ASTEROID_RIGHTS,
    CardName_1.CardName.ASTEROID_DEFLECTION_SYSTEM,
    CardName_1.CardName.COMET_AIMING,
    CardName_1.CardName.ROTATOR_IMPACTS,
    CardName_1.CardName.DIRECTED_IMPACTORS,
    CardName_1.CardName.ASTEROID_HOLLOWING,
    CardName_1.CardName.PHYSICS_COMPLEX,
    CardName_1.CardName.ORE_PROCESSOR,
    CardName_1.CardName.STEELWORKS,
    CardName_1.CardName.IRONWORKS,
    CardName_1.CardName.WATER_SPLITTING_PLANT,
    CardName_1.CardName.VENUS_MAGNETIZER,
    CardName_1.CardName.EQUATORIAL_MAGNETIZER,
    CardName_1.CardName.AQUIFER_PUMPING,
    CardName_1.CardName.WATER_IMPORT_FROM_EUROPA,
    CardName_1.CardName.CARETAKER_CONTRACT,
    CardName_1.CardName.SELF_REPLICATING_ROBOTS,
    CardName_1.CardName.MARTIAN_MEDIA_CENTER,
    CardName_1.CardName.SPACE_MIRRORS,
    CardName_1.CardName.INDUSTRIAL_CENTER,
    CardName_1.CardName.INDUSTRIAL_CENTER_ARES,
    CardName_1.CardName.UNDERGROUND_DETONATIONS,
    CardName_1.CardName.PREDATORS,
    CardName_1.CardName.PENGUINS,
    CardName_1.CardName.FISH,
    CardName_1.CardName.BIRDS,
    CardName_1.CardName.LIVESTOCK,
    CardName_1.CardName.STRATOSPHERIC_BIRDS,
    CardName_1.CardName.BIOENGINEERING_ENCLOSURE,
    CardName_1.CardName.SMALL_ANIMALS,
    CardName_1.CardName.SUBZERO_SALT_FISH,
    CardName_1.CardName.VENUSIAN_ANIMALS,
    CardName_1.CardName.OCEAN_SANCTUARY,
    CardName_1.CardName.PETS,
    CardName_1.CardName.ECOLOGICAL_ZONE,
    CardName_1.CardName.ECOLOGICAL_ZONE_ARES,
    CardName_1.CardName.HERBIVORES,
    CardName_1.CardName.SULPHUR_EATING_BACTERIA,
    CardName_1.CardName.PSYCHROPHILES,
    CardName_1.CardName.EXTREME_COLD_FUNGUS,
    CardName_1.CardName.SYMBIOTIC_FUNGUS,
    CardName_1.CardName.EXTREMOPHILES,
    CardName_1.CardName.ANTS,
    CardName_1.CardName.VENUSIAN_INSECTS,
    CardName_1.CardName.REGOLITH_EATERS,
    CardName_1.CardName.GHG_PRODUCING_BACTERIA,
    CardName_1.CardName.THERMOPHILES,
    CardName_1.CardName.NITRITE_REDUCING_BACTERIA,
    CardName_1.CardName.TARDIGRADES,
    CardName_1.CardName.DECOMPOSERS,
    CardName_1.CardName.MAXWELL_BASE,
    CardName_1.CardName.MOHOLE_LAKE,
    CardName_1.CardName.SEARCH_FOR_LIFE,
    CardName_1.CardName.REFUGEE_CAMPS,
    CardName_1.CardName.SECURITY_FLEET,
    CardName_1.CardName.EVENT_ANALYSTS,
    CardName_1.CardName.PROTECTED_HABITATS,
    CardName_1.CardName.ARCTIC_ALGAE,
    CardName_1.CardName.VIRAL_ENHANCERS,
    CardName_1.CardName.ECOLOGICAL_SURVEY,
    CardName_1.CardName.ADAPTATION_TECHNOLOGY,
    CardName_1.CardName.GEOLOGICAL_SURVEY,
    CardName_1.CardName.IMMIGRANT_CITY,
    CardName_1.CardName.ADVERTISING,
    CardName_1.CardName.MARKETING_EXPERTS,
    CardName_1.CardName.ADVANCED_ALLOYS,
    CardName_1.CardName.REGO_PLASTICS,
    CardName_1.CardName.MERCURIAN_ALLOYS,
].map((card, index) => [card, index + 1]));
