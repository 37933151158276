"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Button_vue_1 = require("@/client/components/common/Button.vue");
const vueRoot_1 = require("@/client/components/vueRoot");
const constants = require("@/common/constants");
const paths = require("@/common/app/paths");
const HTTPResponseCode = require("@/client/utils/HTTPResponseCode");
exports.default = vue_1.default.extend({
    name: 'LoadGameForm',
    components: {
        Button: Button_vue_1.default,
    },
    data() {
        return {
            constants,
            gameId: '',
            rollbackCount: '0',
        };
    },
    methods: {
        loadGame() {
            const gameId = this.$data.gameId;
            const rollbackCount = this.$data.rollbackCount;
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', paths.LOAD_GAME);
            xhr.onerror = function () {
                alert('Error loading game');
            };
            xhr.onload = () => {
                if (xhr.status === HTTPResponseCode.OK) {
                    const response = xhr.response;
                    if (response.players.length === 1) {
                        window.location.href = 'player?id=' + response.players[0].id;
                        return;
                    }
                    else {
                        window.history.replaceState(response, `${constants.APP_NAME} - Game`, 'game?id=' + response.id);
                        (0, vueRoot_1.vueRoot)(this).game = response;
                        (0, vueRoot_1.vueRoot)(this).screen = 'game-home';
                    }
                }
                else {
                    alert('Unexpected server response');
                }
            };
            const loadGameFormModel = {
                game_id: gameId,
                rollbackCount: rollbackCount,
            };
            xhr.responseType = 'json';
            xhr.send(JSON.stringify(loadGameFormModel));
        },
    },
});
