"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vueRoot_1 = require("@/client/components/vueRoot");
const PartyName_1 = require("@/common/turmoil/PartyName");
const GlobalEvent_vue_1 = require("@/client/components/turmoil/GlobalEvent.vue");
const Agendas_vue_1 = require("@/client/components/turmoil/Agendas.vue");
exports.default = vue_1.default.extend({
    name: 'turmoil',
    props: {
        turmoil: {
            type: Object,
        },
    },
    methods: {
        partyNameToCss(party) {
            if (party === undefined) {
                console.warn('no party provided');
                return '';
            }
            return party.toLowerCase().split(' ').join('_');
        },
        getBonus(party) {
            const politicalAgendas = this.turmoil.politicalAgendas;
            if (politicalAgendas !== undefined) {
                switch (party) {
                    case PartyName_1.PartyName.MARS:
                        return politicalAgendas.marsFirst.bonusId;
                    case PartyName_1.PartyName.SCIENTISTS:
                        return politicalAgendas.scientists.bonusId;
                    case PartyName_1.PartyName.UNITY:
                        return politicalAgendas.unity.bonusId;
                    case PartyName_1.PartyName.KELVINISTS:
                        return politicalAgendas.kelvinists.bonusId;
                    case PartyName_1.PartyName.REDS:
                        return politicalAgendas.reds.bonusId;
                    case PartyName_1.PartyName.GREENS:
                        return politicalAgendas.greens.bonusId;
                }
            }
            return undefined;
        },
        getPolicy(partyName) {
            const politicalAgendas = this.turmoil.politicalAgendas;
            if (politicalAgendas !== undefined) {
                switch (partyName) {
                    case PartyName_1.PartyName.MARS:
                        return politicalAgendas.marsFirst.policyId;
                    case PartyName_1.PartyName.SCIENTISTS:
                        return politicalAgendas.scientists.policyId;
                    case PartyName_1.PartyName.UNITY:
                        return politicalAgendas.unity.policyId;
                    case PartyName_1.PartyName.KELVINISTS:
                        return politicalAgendas.kelvinists.policyId;
                    case PartyName_1.PartyName.REDS:
                        return politicalAgendas.reds.policyId;
                    case PartyName_1.PartyName.GREENS:
                        return politicalAgendas.greens.policyId;
                }
            }
            return undefined;
        },
        toggleMe() {
            const currentState = this.isVisible();
            (0, vueRoot_1.vueRoot)(this).setVisibilityState('turmoil_parties', !currentState);
        },
        isVisible() {
            return (0, vueRoot_1.vueRoot)(this).getVisibilityState('turmoil_parties');
        },
    },
    components: {
        'global-event': GlobalEvent_vue_1.default,
        'agendas': Agendas_vue_1.default,
    },
});
