"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const CardOrderStorage_1 = require("@/client/utils/CardOrderStorage");
exports.default = vue_1.default.extend({
    name: 'SortableCards',
    components: {
        Card: Card_vue_1.default,
    },
    props: {
        cards: {
            type: Array,
        },
        playerId: {
            type: String,
        },
    },
    data() {
        const cache = CardOrderStorage_1.CardOrderStorage.getCardOrder(this.playerId);
        const cardOrder = {};
        const keys = Object.keys(cache);
        let max = 0;
        for (const key of keys) {
            if (this.cards.find((card) => card.name === key) !== undefined) {
                cardOrder[key] = cache[key];
                max = Math.max(max, cache[key]);
            }
        }
        max++;
        for (const card of this.cards) {
            if (cardOrder[card.name] === undefined) {
                cardOrder[card.name] = max++;
            }
        }
        return {
            cardOrder,
            dragCard: undefined,
        };
    },
    methods: {
        getSortedCards() {
            return CardOrderStorage_1.CardOrderStorage.getOrdered(this.cardOrder, this.cards);
        },
        onDragStart(source) {
            this.dragCard = source;
        },
        onDragEnd() {
            this.dragCard = undefined;
        },
        onDragOver(source) {
            if (this.dragCard === undefined || source === this.dragCard) {
                return;
            }
            if (source === 'end') {
                let max = 0;
                const keys = Object.keys(this.cardOrder);
                for (const key of keys) {
                    max = Math.max(max, this.cardOrder[key]);
                }
                this.cardOrder[this.dragCard] = max + 1;
            }
            else {
                const temp = this.cardOrder[source];
                const keys = Object.keys(this.cardOrder);
                for (const key of keys) {
                    if (this.cardOrder[key] >= temp) {
                        this.cardOrder[key]++;
                    }
                }
                this.cardOrder[this.dragCard] = temp;
            }
            CardOrderStorage_1.CardOrderStorage.updateCardOrder(this.playerId, this.cardOrder);
        },
    },
});
