"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const LanguageSwitcher_vue_1 = require("@/client/components/LanguageSwitcher.vue");
const PreferencesIcon_vue_1 = require("@/client/components/PreferencesIcon.vue");
const raw_settings = require("@/genfiles/settings.json");
const constants = require("@/common/constants");
exports.default = vue_1.default.extend({
    name: 'start-screen',
    components: {
        LanguageSwitcher: LanguageSwitcher_vue_1.default,
        PreferencesIcon: PreferencesIcon_vue_1.default,
    },
    computed: {
        raw_settings() {
            return raw_settings;
        },
        DISCORD_INVITE() {
            return constants.DISCORD_INVITE;
        },
    },
});
