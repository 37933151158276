"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardResource = void 0;
var CardResource;
(function (CardResource) {
    CardResource["ANIMAL"] = "Animal";
    CardResource["MICROBE"] = "Microbe";
    CardResource["FIGHTER"] = "Fighter";
    CardResource["SCIENCE"] = "Science";
    CardResource["FLOATER"] = "Floater";
    CardResource["ASTEROID"] = "Asteroid";
    CardResource["PRESERVATION"] = "Preservation";
    CardResource["CAMP"] = "Camp";
    CardResource["DISEASE"] = "Disease";
    CardResource["RESOURCE_CUBE"] = "Resource cube";
    CardResource["DATA"] = "Data";
    CardResource["SYNDICATE_FLEET"] = "Syndicate Fleet";
    CardResource["VENUSIAN_HABITAT"] = "Venusian Habitat";
    CardResource["SPECIALIZED_ROBOT"] = "Specialized Robot";
    CardResource["SEED"] = "Seed";
    CardResource["AGENDA"] = "Agenda";
    CardResource["ORBITAL"] = "Orbital";
})(CardResource = exports.CardResource || (exports.CardResource = {}));
