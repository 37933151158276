"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Button_vue_1 = require("@/client/components/common/Button.vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const PlayerInputType_1 = require("@/common/input/PlayerInputType");
let unique = 0;
exports.default = vue_1.default.extend({
    name: 'or-options',
    props: {
        playerView: {
            type: Object,
        },
        players: {
            type: Array,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        Button: Button_vue_1.default,
    },
    data() {
        var _a;
        if (this.playerinput.options === undefined) {
            throw new Error('no options provided for OrOptions');
        }
        const displayedOptions = this.playerinput.options.filter((o) => Boolean(o.showOnlyInLearnerMode) === false || (0, PreferencesManager_1.getPreferences)().learner_mode);
        let selectedOption = displayedOptions[0];
        if (displayedOptions.length > 1 &&
            selectedOption.inputType === PlayerInputType_1.PlayerInputType.SELECT_CARD &&
            !((_a = selectedOption.cards) === null || _a === void 0 ? void 0 : _a.some((card) => card.isDisabled === false))) {
            selectedOption = displayedOptions[1];
        }
        return {
            displayedOptions,
            radioElementName: 'selectOption' + unique++,
            selectedOption,
        };
    },
    methods: {
        playerFactorySaved() {
            var _a;
            const idx = (_a = this.playerinput.options) === null || _a === void 0 ? void 0 : _a.indexOf(this.selectedOption);
            if (idx === undefined || idx === -1) {
                throw new Error('option not found');
            }
            return (out) => {
                this.onsave({
                    type: 'or',
                    index: idx,
                    response: out,
                });
            };
        },
        saveData() {
            let ref = this.$refs['inputfactory'];
            if (Array.isArray(ref)) {
                ref = ref[0];
            }
            ref.saveData();
        },
    },
});
