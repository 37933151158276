"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const HTMLDialogElementCompatibility_1 = require("@/client/components/HTMLDialogElementCompatibility");
const dialogPolyfill = require('dialog-polyfill');
exports.default = vue_1.default.extend({
    name: 'ConfirmDialog',
    props: {
        message: {
            type: String,
        },
        enableDontShowAgainCheckbox: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hide: false,
            shown: false,
        };
    },
    watch: {
        hide() {
            this.$emit('hide', this.hide);
        },
    },
    methods: {
        accept() {
            this.$emit('accept');
        },
        dismiss() {
            this.$emit('dismiss');
        },
        show() {
            this.shown = true;
            (0, HTMLDialogElementCompatibility_1.showModal)(this.$refs.dialog);
        },
    },
    mounted() {
        if (!(0, HTMLDialogElementCompatibility_1.windowHasHTMLDialogElement)())
            dialogPolyfill.default.registerDialog(this.$refs.dialog);
    },
});
