"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const ColonyName_1 = require("@/common/colonies/ColonyName");
const ColonyRow_vue_1 = require("@/client/components/colonies/ColonyRow.vue");
const ColonyTradeRow_vue_1 = require("@/client/components/colonies/ColonyTradeRow.vue");
const ClientColonyManifest_1 = require("@/client/colonies/ClientColonyManifest");
const ColonyBenefit_1 = require("@/common/colonies/ColonyBenefit");
const Resources_1 = require("@/common/Resources");
exports.default = vue_1.default.extend({
    name: 'colony',
    props: {
        colony: {
            type: Object,
        },
    },
    components: {
        ColonyRow: ColonyRow_vue_1.default,
        ColonyTradeRow: ColonyTradeRow_vue_1.default,
    },
    computed: {
        cubeXPosition() {
            return this.colony.trackPosition * 56 + 27;
        },
        colonyXPositions() {
            return [0, 1, 2].map((index) => index * 56 + 16);
        },
        colonyCubeOffset() {
            return 7;
        },
        cubeYPosition() {
            switch (this.colony.name) {
                case ColonyName_1.ColonyName.IAPETUS:
                case ColonyName_1.ColonyName.LEAVITT:
                    return 181;
                case ColonyName_1.ColonyName.VENUS:
                    return 186;
                case ColonyName_1.ColonyName.PALLAS:
                    return 168;
                case ColonyName_1.ColonyName.MERCURY:
                case ColonyName_1.ColonyName.HYGIEA:
                    return 144;
                case ColonyName_1.ColonyName.EUROPA:
                case ColonyName_1.ColonyName.MIRANDA:
                    return 166;
                case ColonyName_1.ColonyName.PLUTO:
                    return 165;
                case ColonyName_1.ColonyName.LUNA:
                    return 163;
                default:
                    return 164;
            }
        },
        getColonyContentOffset() {
            if (this.colony.name === ColonyName_1.ColonyName.PLUTO || this.colony.name === ColonyName_1.ColonyName.MIRANDA)
                return -12;
            return 0;
        },
        metadata() {
            return (0, ClientColonyManifest_1.getColony)(this.colony.name);
        },
        colonyResourceClass() {
            var _a, _b;
            const resource = this.metadata.resourceType;
            return (_b = (_a = resource === null || resource === void 0 ? void 0 : resource.toString()) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '';
        },
        backgroundClass() {
            return this.colony.name.replace(' ', '-') + '-background';
        },
        ColonyName() {
            return ColonyName_1.ColonyName;
        },
        ColonyBenefit() {
            return ColonyBenefit_1.ColonyBenefit;
        },
        Resources() {
            return Resources_1.Resources;
        },
    },
});
