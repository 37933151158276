"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'Tag',
    props: {
        tag: {
            type: String,
        },
        size: {
            type: String,
        },
        type: {
            type: String,
        },
    },
    methods: {
        getClasses() {
            const classes = ['tag-count'];
            classes.push(`tag-${this.tag}`);
            if (this.size !== undefined) {
                classes.push(`tag-size-${this.size}`);
            }
            if (this.type !== undefined) {
                classes.push(`tag-type-${this.type}`);
            }
            return classes.join(' ');
        },
    },
});
