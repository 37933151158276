"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerMixin = void 0;
const ActiveCardsSortingOrder_1 = require("@/client/utils/ActiveCardsSortingOrder");
exports.PlayerMixin = {
    name: 'PlayerMixin',
    methods: {
        sortActiveCards: ActiveCardsSortingOrder_1.sortActiveCards,
        getCardsByType(inCards, cardTypes) {
            const cards = inCards.filter((card) => cardTypes.includes(card.cardType));
            return cards.reverse();
        },
        isCardActivated(card, player) {
            return player.actionsThisGeneration.includes(card.name) || card.isDisabled;
        },
    },
};
