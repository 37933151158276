"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Colony_vue_1 = require("@/client/components/colonies/Colony.vue");
const Button_vue_1 = require("@/client/components/common/Button.vue");
exports.default = vue_1.default.extend({
    name: 'SelectColony',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        return {
            selectedColony: undefined,
        };
    },
    components: {
        'colony': Colony_vue_1.default,
        Button: Button_vue_1.default,
    },
    methods: {
        canSave() {
            return this.selectedColony !== undefined;
        },
        saveData() {
            if (this.selectedColony !== undefined) {
                this.onsave({ type: 'colony', colonyName: this.selectedColony });
            }
        },
    },
});
