"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'CardCost',
    props: {
        amount: {
            type: Number,
        },
        newCost: {
            type: Number,
        },
    },
    methods: {
        getClasses() {
            const classes = ['card-cost'];
            if (this.amount === undefined) {
                classes.push('visibility-hidden');
            }
            return classes.join(' ');
        },
        displayTwoCosts() {
            const hideDiscount = (0, PreferencesManager_1.getPreferences)().hide_discount_on_cards;
            return this.newCost !== undefined && this.newCost !== this.amount && !hideDiscount;
        },
    },
});
