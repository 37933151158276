"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const ColonyName_1 = require("@/common/colonies/ColonyName");
const ColonyDescription_1 = require("@/common/colonies/ColonyDescription");
const AllColonies_1 = require("@/common/colonies/AllColonies");
exports.default = vue_1.default.extend({
    name: 'ColoniesFilter',
    props: {
        communityCardsOption: {
            type: Boolean,
        },
        venusNext: {
            type: Boolean,
        },
        turmoil: {
            type: Boolean,
        },
        pathfinders: {
            type: Boolean,
        },
    },
    data() {
        const officialColonies = [...AllColonies_1.OFFICIAL_COLONY_NAMES].sort();
        const communityColonies = [...AllColonies_1.COMMUNITY_COLONY_NAMES].sort();
        const pathfindersColonies = [...AllColonies_1.PATHFINDERS_COLONY_NAMES].sort();
        const data = {
            allColonies: officialColonies.concat(communityColonies),
            officialColonies,
            communityColonies,
            pathfindersColonies,
            selectedColonies: [
                ...officialColonies,
                ...this.communityCardsOption ? communityColonies : [],
                ...this.pathfinders ? pathfindersColonies : [],
            ],
            modules: ['colonies', 'community', 'pathfinders'],
        };
        return data;
    },
    methods: {
        updateColoniesByNames(colonyNames) {
            this.selectedColonies = [];
            for (const colony of this.allColonies) {
                if (colonyNames.includes(colony)) {
                    this.selectedColonies.push(colony);
                }
            }
        },
        description(colonyName) {
            var _a;
            return (_a = ColonyDescription_1.COLONY_DESCRIPTIONS.get(colonyName)) !== null && _a !== void 0 ? _a : 'unknown';
        },
        getItemsByGroup(group) {
            switch (group) {
                case 'All': return this.allColonies;
                case 'colonies': return this.officialColonies;
                case 'community': return this.communityColonies;
                default: return [];
            }
        },
        selectAll(group) {
            const items = this.getItemsByGroup(group);
            for (const item of items) {
                if (this.selectedColonies.includes(item) === false) {
                    this.selectedColonies.push(item);
                }
            }
        },
        removeFromSelection(colonyName) {
            const itemIdx = this.selectedColonies.indexOf(colonyName);
            if (itemIdx !== -1) {
                this.selectedColonies.splice(itemIdx, 1);
            }
        },
        selectNone(group) {
            const items = this.getItemsByGroup(group);
            for (const item of items) {
                this.removeFromSelection(item);
            }
        },
        invertSelection(group) {
            const items = this.getItemsByGroup(group);
            for (const idx in items) {
                if (this.selectedColonies.includes(items[idx])) {
                    this.removeFromSelection(items[idx]);
                }
                else {
                    this.selectedColonies.push(items[idx]);
                }
            }
        },
        title(module) {
            if (module === 'colonies')
                return 'Official';
            if (module === 'community')
                return 'Community';
            if (module === 'pathfinders')
                return 'Pathfinders';
            return module;
        },
        getColonies(module) {
            if (module === 'colonies')
                return this.officialColonies;
            if (module === 'community')
                return this.communityColonies;
            if (module === 'pathfinders')
                return this.pathfindersColonies;
            return [];
        },
    },
    watch: {
        selectedColonies(value) {
            const colonyNames = [...value];
            this.$emit('colonies-list-changed', colonyNames);
        },
        communityCardsOption(enabled) {
            if (enabled) {
                this.selectedColonies = AllColonies_1.OFFICIAL_COLONY_NAMES.concat(AllColonies_1.COMMUNITY_COLONY_NAMES).slice();
                if (this.venusNext === false)
                    this.selectedColonies = this.selectedColonies.filter((c) => c !== ColonyName_1.ColonyName.VENUS);
                if (this.turmoil === false)
                    this.selectedColonies = this.selectedColonies.filter((c) => c !== ColonyName_1.ColonyName.PALLAS);
            }
            else {
                this.selectedColonies = AllColonies_1.OFFICIAL_COLONY_NAMES.slice();
            }
        },
        venusNext(enabled) {
            if (this.communityCardsOption && Array.isArray(this.selectedColonies)) {
                if (enabled === false) {
                    this.selectedColonies = this.selectedColonies.filter((c) => c !== ColonyName_1.ColonyName.VENUS);
                }
                else if (!this.selectedColonies.includes(ColonyName_1.ColonyName.VENUS)) {
                    this.selectedColonies.push(ColonyName_1.ColonyName.VENUS);
                }
            }
        },
        turmoil(enabled) {
            if (this.communityCardsOption && Array.isArray(this.selectedColonies)) {
                if (enabled === false) {
                    this.selectedColonies = this.selectedColonies.filter((c) => c !== ColonyName_1.ColonyName.PALLAS);
                }
                else if (!this.selectedColonies.includes(ColonyName_1.ColonyName.PALLAS)) {
                    this.selectedColonies.push(ColonyName_1.ColonyName.PALLAS);
                }
            }
        },
        pathfinders(_enabled) {
        },
    },
});
