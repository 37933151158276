"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const BugReportDialog_vue_1 = require("@/client/components/BugReportDialog.vue");
exports.default = vue_1.default.extend({
    name: 'PreferencesDialog',
    props: {
        preferencesManager: {
            type: Object,
        },
    },
    components: {
        'bug-report-dialog': BugReportDialog_vue_1.default,
    },
    data() {
        return {
            prefs: Object.assign({}, this.preferencesManager.values()),
        };
    },
    methods: {
        setBoolPreferencesCSS(target, val, name) {
            const cssClassSuffix = name;
            if (val) {
                target.classList.add('preferences_' + cssClassSuffix);
            }
            else {
                target.classList.remove('preferences_' + cssClassSuffix);
            }
        },
        updatePreferences() {
            for (const k of Object.keys(this.preferencesManager.values())) {
                const val = this.prefs[k];
                this.preferencesManager.set(k, val, true);
            }
        },
        syncPreferences() {
            const target = document.getElementById('ts-preferences-target');
            if (!target)
                return;
            for (const k of Object.keys(this.prefs)) {
                if (k === 'lang')
                    continue;
                this.setBoolPreferencesCSS(target, this.prefs[k], k);
            }
            if (!target.classList.contains('language-' + this.prefs.lang)) {
                target.classList.add('language-' + this.prefs.lang);
            }
        },
        okClicked() {
            this.$emit('okButtonClicked');
        },
    },
    computed: {
        getPreferences() {
            return PreferencesManager_1.getPreferences;
        },
    },
});
