"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const ColonyName_1 = require("@/common/colonies/ColonyName");
const BuildBenefit_vue_1 = require("./BuildBenefit.vue");
exports.default = vue_1.default.extend({
    components: { BuildBenefit: BuildBenefit_vue_1.default },
    name: 'ColonyRow',
    props: {
        metadata: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ColonyName() {
            return ColonyName_1.ColonyName;
        },
    },
});
