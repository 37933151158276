"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderSymbolType = void 0;
var CardRenderSymbolType;
(function (CardRenderSymbolType) {
    CardRenderSymbolType["ASTERIX"] = "*";
    CardRenderSymbolType["OR"] = "OR";
    CardRenderSymbolType["MINUS"] = "-";
    CardRenderSymbolType["PLUS"] = "+";
    CardRenderSymbolType["COLON"] = ":";
    CardRenderSymbolType["EMPTY"] = " ";
    CardRenderSymbolType["SLASH"] = "/";
    CardRenderSymbolType["ARROW"] = "->";
    CardRenderSymbolType["BRACKET_OPEN"] = "(";
    CardRenderSymbolType["BRACKET_CLOSE"] = ")";
    CardRenderSymbolType["NBSP"] = "nbsp";
    CardRenderSymbolType["VSPACE"] = "vspace";
    CardRenderSymbolType["EQUALS"] = "=";
    CardRenderSymbolType["SURVEY_MISSION"] = "survey-mission";
})(CardRenderSymbolType = exports.CardRenderSymbolType || (exports.CardRenderSymbolType = {}));
