"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants = require("@/common/constants");
const BoardSpace_vue_1 = require("@/client/components/BoardSpace.vue");
const SpaceType_1 = require("@/common/boards/SpaceType");
const BoardName_1 = require("@/common/boards/BoardName");
class GlobalParamLevel {
    constructor(value, isActive, strValue) {
        this.value = value;
        this.isActive = isActive;
        this.strValue = strValue;
    }
}
exports.default = vue_1.default.extend({
    name: 'board',
    props: {
        spaces: {
            type: Array,
        },
        venusNextExtension: {
            type: Boolean,
        },
        venusScaleLevel: {
            type: Number,
        },
        altVenusBoard: {
            type: Boolean,
        },
        boardName: {
            type: String,
        },
        oceans_count: {
            type: Number,
        },
        oxygen_level: {
            type: Number,
        },
        temperature: {
            type: Number,
        },
        aresExtension: {
            type: Boolean,
        },
        pathfindersExpansion: {
            type: Boolean,
        },
        aresData: {
            type: Object,
        },
        tileView: {
            type: String,
            default: 'show',
        },
    },
    components: {
        BoardSpace: BoardSpace_vue_1.default,
    },
    data() {
        return {
            constants,
        };
    },
    methods: {
        getAllSpacesOnMars() {
            const boardSpaces = [...this.spaces];
            boardSpaces.sort((space1, space2) => {
                return parseInt(space1.id) - parseInt(space2.id);
            });
            return boardSpaces.filter((s) => {
                return s.spaceType !== SpaceType_1.SpaceType.COLONY;
            });
        },
        getSpaceById(spaceId) {
            for (const space of this.spaces) {
                if (space.id === spaceId) {
                    return space;
                }
            }
            throw new Error('Board space not found by id \'' + spaceId + '\'');
        },
        getValuesForParameter(targetParameter) {
            const values = [];
            let startValue;
            let endValue;
            let step;
            let curValue;
            let strValue;
            switch (targetParameter) {
                case 'oxygen':
                    startValue = constants.MIN_OXYGEN_LEVEL;
                    endValue = constants.MAX_OXYGEN_LEVEL;
                    step = 1;
                    curValue = this.oxygen_level;
                    break;
                case 'temperature':
                    startValue = constants.MIN_TEMPERATURE;
                    endValue = constants.MAX_TEMPERATURE;
                    step = 2;
                    curValue = this.temperature;
                    break;
                case 'venus':
                    startValue = constants.MIN_VENUS_SCALE;
                    endValue = constants.MAX_VENUS_SCALE;
                    step = 2;
                    curValue = this.venusScaleLevel;
                    break;
                default:
                    throw new Error('Wrong parameter to get values from: ' + targetParameter);
            }
            for (let value = endValue; value >= startValue; value -= step) {
                strValue = (targetParameter === 'temperature' && value > 0) ? '+' + value : value.toString();
                values.push(new GlobalParamLevel(value, value === curValue, strValue));
            }
            return values;
        },
        getScaleCSS(paramLevel) {
            let css = 'global-numbers-value val-' + paramLevel.value + ' ';
            if (paramLevel.isActive) {
                css += 'val-is-active';
            }
            return css;
        },
        oceansValue() {
            const oceans_count = this.oceans_count || 0;
            const leftover = constants.MAX_OCEAN_TILES - oceans_count;
            if (leftover === 0) {
                return '<img width="26" src="assets/misc/circle-checkmark.png" class="board-ocean-checkmark" :alt="$t(\'Completed!\')">';
            }
            else {
                return `${oceans_count}/${constants.MAX_OCEAN_TILES}`;
            }
        },
        getGameBoardClassName() {
            return this.venusNextExtension ? 'board-cont board-with-venus' : 'board-cont board-without-venus';
        },
    },
    computed: {
        BoardName() {
            return BoardName_1.BoardName;
        },
    },
});
