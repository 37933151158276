"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Card_vue_1 = require("@/client/components/card/Card.vue");
exports.default = vue_1.default.extend({
    name: 'stacked-cards',
    props: {
        cards: {
            type: Array,
        },
    },
    components: {
        Card: Card_vue_1.default,
    },
});
