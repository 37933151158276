"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const utils_1 = require("@/common/utils/utils");
const TileType_1 = require("@/common/TileType");
const TILE_CLASSES = new Map([
    [TileType_1.TileType.BIOFERTILIZER_FACILITY, {
            aresTile: 'card-tile-biofertilizer-facility',
        }],
    [TileType_1.TileType.CAPITAL, {
            tile: 'card-tile-capital',
            aresTile: 'card-tile-capital-ares',
        }],
    [TileType_1.TileType.COMMERCIAL_DISTRICT, {
            aresTile: 'card-tile-commercial-district-ares',
            symbol: 'card-tile-symbol-commercial-district',
        }],
    [TileType_1.TileType.DEIMOS_DOWN, {
            symbol: 'card-tile-symbol-deimos-down',
        }],
    [TileType_1.TileType.GREAT_DAM, {
            symbol: 'card-tile-symbol-great-dam',
        }],
    [TileType_1.TileType.ECOLOGICAL_ZONE, {
            aresTile: 'card-tile-ecological-zone-ares',
            symbol: 'card-tile-symbol-ecological-zone',
        }],
    [TileType_1.TileType.INDUSTRIAL_CENTER, {
            aresTile: 'card-tile-industrial-center-ares',
            symbol: 'card-tile-symbol-industrial-center',
        }],
    [TileType_1.TileType.LAVA_FLOWS, {
            aresTile: 'card-tile-lava-flows-ares',
            symbol: 'card-tile-symbol-lava-flows',
        }],
    [TileType_1.TileType.LUNA_TRADE_STATION, {
            symbol: 'card-tile-symbol-luna-trade-station',
        }],
    [TileType_1.TileType.LUNA_MINING_HUB, {
            symbol: 'card-tile-symbol-luna-mining-hub',
        }],
    [TileType_1.TileType.LUNA_TRAIN_STATION, {
            symbol: 'card-tile-symbol-luna-train-station',
        }],
    [TileType_1.TileType.LUNAR_MINE_URBANIZATION, {
            symbol: 'card-tile-symbol-lunar-mine-urbanization',
        }],
    [TileType_1.TileType.MAGNETIC_FIELD_GENERATORS, {
            symbol: 'card-tile-symbol-magnetic-field-generators',
        }],
    [TileType_1.TileType.METALLIC_ASTEROID, {
            aresTile: 'card-tile-metallic-asteroid',
        }],
    [TileType_1.TileType.MINING_AREA, {
            symbol: 'card-tile-symbol-mining',
        }],
    [TileType_1.TileType.MINING_RIGHTS, {
            symbol: 'card-tile-symbol-mining',
        }],
    [TileType_1.TileType.MINING_STEEL_BONUS, {
            tile: 'card-tile-mining-steel',
        }],
    [TileType_1.TileType.MINING_TITANIUM_BONUS, {
            tile: 'card-tile-mining-titanium',
        }],
    [TileType_1.TileType.MOHOLE_AREA, {
            aresTile: 'card-tile-mohole-area-ares',
            symbol: 'card-tile-symbol-mohole-area',
        }],
    [TileType_1.TileType.MOON_MINE, {
            tile: 'card-tile-lunar-mine',
        }],
    [TileType_1.TileType.MOON_HABITAT, {
            tile: 'card-tile-lunar-colony',
        }],
    [TileType_1.TileType.MOON_ROAD, {
            tile: 'card-tile-lunar-road',
        }],
    [TileType_1.TileType.NATURAL_PRESERVE, {
            aresTile: 'card-tile-natural-preserve-ares',
            symbol: 'card-tile-symbol-natural-preserve',
        }],
    [TileType_1.TileType.NUCLEAR_ZONE, {
            aresTile: 'card-tile-nuclear-zone-ares',
            symbol: 'card-tile-symbol-nuclear-zone',
        }],
    [TileType_1.TileType.OCEAN_CITY, {
            tile: 'card-tile-ocean-city',
        }],
    [TileType_1.TileType.OCEAN_FARM, {
            tile: 'card-tile-ocean-farm',
        }],
    [TileType_1.TileType.OCEAN_SANCTUARY, {
            tile: 'card-tile-ocean-sanctuary',
        }],
    [TileType_1.TileType.RESTRICTED_AREA, {
            aresTile: 'card-tile-restricted-area-ares',
            symbol: 'card-tile-symbol-restricted-area',
        }],
    [TileType_1.TileType.SOLAR_FARM, {
            tile: 'card-tile-solar-farm',
        }],
    [TileType_1.TileType.WETLANDS, {
            tile: 'card-tile-wetlands',
        }],
]);
exports.default = vue_1.default.extend({
    name: 'CardRenderTileComponent',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        tiles() {
            const classes = ['card-tile'];
            if (this.item.hasSymbol) {
                classes.push('card-tile-canvas');
            }
            const symbolClass = TILE_CLASSES.get(this.item.tile);
            if (this.item.isAres && (symbolClass === null || symbolClass === void 0 ? void 0 : symbolClass.aresTile) !== undefined) {
                classes.push(symbolClass.aresTile);
            }
            else if ((symbolClass === null || symbolClass === void 0 ? void 0 : symbolClass.tile) !== undefined) {
                classes.push(symbolClass.tile);
            }
            return (0, utils_1.generateClassString)(classes);
        },
        symbols() {
            const classes = [];
            if (this.item.hasSymbol) {
                const symbolClass = TILE_CLASSES.get(this.item.tile);
                if ((symbolClass === null || symbolClass === void 0 ? void 0 : symbolClass.symbol) !== undefined) {
                    classes.push('card-tile-symbol');
                    classes.push(symbolClass.symbol);
                }
            }
            return (0, utils_1.generateClassString)(classes);
        },
    },
});
