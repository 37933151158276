"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const StackedCards_vue_1 = require("@/client/components/StackedCards.vue");
const PlayerMixin_1 = require("@/client/mixins/PlayerMixin");
const vueRoot_1 = require("@/client/components/vueRoot");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const Button_vue_1 = require("@/client/components/common/Button.vue");
const CardType_1 = require("@/common/cards/CardType");
exports.default = vue_1.default.extend({
    name: 'OtherPlayer',
    props: {
        player: {
            type: Object,
        },
        playerIndex: {
            type: Number,
        },
    },
    components: {
        Button: Button_vue_1.default,
        'stacked-cards': StackedCards_vue_1.default,
        Card: Card_vue_1.default,
    },
    methods: Object.assign(Object.assign({}, PlayerMixin_1.PlayerMixin.methods), { hideMe() {
            (0, vueRoot_1.vueRoot)(this).setVisibilityState('pinned_player_' + this.playerIndex, false);
        },
        isVisible() {
            return (0, vueRoot_1.vueRoot)(this).getVisibilityState('pinned_player_' + this.playerIndex);
        } }),
    computed: {
        CardType() {
            return CardType_1.CardType;
        },
    },
});
