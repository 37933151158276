"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.awardNames = void 0;
exports.awardNames = [
    'Landlord',
    'Scientist',
    'Banker',
    'Thermalist',
    'Miner',
    'Celebrity',
    'Industrialist',
    'Desert Settler',
    'Estate Dealer',
    'Benefactor',
    'Contractor',
    'Cultivator',
    'Excentric',
    'Magnate',
    'Space Baron',
    'Venuphile',
    'Entrepreneur',
    'Full Moon',
    'Lunar Magnate',
    'Curator',
    'Engineer',
    'Historian',
    'Tourist',
    'A. Zoologist',
    'Cosmic Settler',
    'Botanist',
    'Coordinator',
    'Manufacturer',
    'Zoologist',
    'Biologist',
    'T. Economizer',
    'Politician',
    'Urbanist',
    'Warmonger',
    'Adapter',
    'Edgedancer',
    'Hoarder',
    'Naturalist',
    'Voyager',
];
