"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const PartyName_1 = require("@/common/turmoil/PartyName");
const GameSetupDetail_vue_1 = require("@/client/components/GameSetupDetail.vue");
const GlobalParameterValue_vue_1 = require("@/client/components/GlobalParameterValue.vue");
const MoonGlobalParameterValue_vue_1 = require("@/client/components/moon/MoonGlobalParameterValue.vue");
const GlobalParameter_1 = require("@/common/GlobalParameter");
const PreferencesIcon_vue_1 = require("@/client/components/PreferencesIcon.vue");
const LanguageIcon_vue_1 = require("@/client/components/LanguageIcon.vue");
exports.default = vue_1.default.extend({
    name: 'sidebar',
    props: {
        playerNumber: {
            type: Number,
        },
        gameOptions: {
            type: Object,
        },
        acting_player: {
            type: Boolean,
        },
        player_color: {
            type: String,
        },
        generation: {
            type: Number,
        },
        coloniesCount: {
            type: Number,
        },
        temperature: {
            type: Number,
        },
        oxygen: {
            type: Number,
        },
        oceans: {
            type: Number,
        },
        venus: {
            type: Number,
        },
        moonData: {
            type: Object,
        },
        turmoil: {
            type: Object || undefined,
        },
        lastSoloGeneration: {
            type: Number,
        },
    },
    components: {
        'game-setup-detail': GameSetupDetail_vue_1.default,
        'global-parameter-value': GlobalParameterValue_vue_1.default,
        MoonGlobalParameterValue: MoonGlobalParameterValue_vue_1.default,
        PreferencesIcon: PreferencesIcon_vue_1.default,
        LanguageIcon: LanguageIcon_vue_1.default,
    },
    data() {
        return {
            'ui': {
                'gamesetup_detail_open': false,
            },
            'globalParameter': GlobalParameter_1.GlobalParameter,
        };
    },
    methods: {
        getPlayerColorCubeClass() {
            return this.acting_player && ((0, PreferencesManager_1.getPreferences)().hide_animated_sidebar === false) ? 'preferences_player_inner active' : 'preferences_player_inner';
        },
        getSideBarClass() {
            return this.acting_player && ((0, PreferencesManager_1.getPreferences)().hide_animated_sidebar === false) ? 'preferences_acting_player' : 'preferences_nonacting_player';
        },
        getGenMarker() {
            return `${this.generation}`;
        },
        rulingPartyToCss() {
            if (this.turmoil.ruling === undefined) {
                console.warn('no party provided');
                return '';
            }
            return this.turmoil.ruling.toLowerCase().split(' ').join('_');
        },
        getRulingParty() {
            switch (this.turmoil.ruling) {
                case PartyName_1.PartyName.MARS:
                    return 'Mars';
                case PartyName_1.PartyName.SCIENTISTS:
                    return 'Science';
                case PartyName_1.PartyName.KELVINISTS:
                    return 'Kelvin';
                case undefined:
                    return '???';
                default:
                    return this.turmoil.ruling;
            }
        },
    },
    computed: {
        preferencesManager() {
            return PreferencesManager_1.PreferencesManager.INSTANCE;
        },
    },
});
