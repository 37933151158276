"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PlanetaryTrackReward_vue_1 = require("./PlanetaryTrackReward.vue");
exports.default = vue_1.default.extend({
    name: 'PlanetaryTrackRewards',
    props: {
        rewards: {
            type: Object,
        },
        type: {
            type: String,
        },
        gameOptions: {
            type: Object,
        },
    },
    components: {
        PlanetaryTrackReward: PlanetaryTrackReward_vue_1.default,
    },
    data() {
        return {};
    },
    computed: {
        myReward() {
            switch (this.type) {
                case 'risingPlayer':
                    return this.rewards.risingPlayer;
                case 'everyone':
                    return this.rewards.everyone.concat(this.rewards.mostTags);
                default:
                    return [];
            }
        },
        mostTags() {
            return this.type === 'everyone' && this.rewards.mostTags.length > 0;
        },
    },
});
