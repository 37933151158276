"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardRowData_vue_1 = require("@/client/components/card/CardRowData.vue");
exports.default = vue_1.default.extend({
    name: 'CardRenderData',
    props: {
        renderData: {
            type: Object,
            required: true,
        },
    },
    components: {
        CardRowData: CardRowData_vue_1.default,
    },
});
