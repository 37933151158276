"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants_1 = require("@/common/constants");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'language-switcher',
    methods: {
        switchLanguageTo(langId) {
            PreferencesManager_1.PreferencesManager.INSTANCE.set('lang', langId);
            window.location.reload();
        },
    },
    computed: {
        LANGUAGES() {
            return constants_1.LANGUAGES;
        },
    },
});
