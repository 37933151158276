"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'SelectPlayerRow',
    props: {
        player: {
            type: Object,
        },
    },
});
