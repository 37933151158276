"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const TileType_1 = require("@/common/TileType");
const Bonus_vue_1 = require("@/client/components/Bonus.vue");
const tileTypeToCssClass = new Map([
    [TileType_1.TileType.MOON_ROAD, 'road'],
    [TileType_1.TileType.MOON_HABITAT, 'colony'],
    [TileType_1.TileType.MOON_MINE, 'mine'],
    [TileType_1.TileType.LUNA_TRADE_STATION, 'luna-trade-station'],
    [TileType_1.TileType.LUNA_MINING_HUB, 'luna-mining-hub'],
    [TileType_1.TileType.LUNA_TRAIN_STATION, 'luna-train-station'],
    [TileType_1.TileType.LUNAR_MINE_URBANIZATION, 'lunar-mine-urbanization'],
]);
exports.default = vue_1.default.extend({
    name: 'MoonSpace',
    props: {
        space: {
            type: Object,
        },
        text: {
            type: String,
        },
        is_selectable: {
            type: Boolean,
        },
        tileView: {
            type: String,
            default: 'show',
        },
    },
    components: {
        Bonus: Bonus_vue_1.default,
    },
    computed: {
        verboseTitle() {
            let ret = '';
            if (this.space.tileType === TileType_1.TileType.LUNA_TRADE_STATION) {
                ret = 'Luna Trade Station';
            }
            else if (this.space.tileType === TileType_1.TileType.LUNA_MINING_HUB) {
                ret = 'Luna Mining Hub';
            }
            else if (this.space.tileType === TileType_1.TileType.LUNA_TRAIN_STATION) {
                ret = 'Luna Train Station';
            }
            return this.$t(ret);
        },
        mainClass() {
            let css = 'board-space moon-space-' + this.space.id.toString();
            if (this.is_selectable) {
                css += ' board-space-selectable';
            }
            if (this.space.spaceType === 'lunar_mine') {
                css += ' moon-space-type-mine';
            }
            else {
                css += ' moon-space-type-other';
            }
            return css;
        },
        tileClass() {
            let css = 'board-space';
            const tileType = this.space.tileType;
            if (tileType !== undefined) {
                switch (this.space.tileType) {
                    case TileType_1.TileType.MOON_HABITAT:
                        css += ' board-space-tile--colony';
                        break;
                    case TileType_1.TileType.MOON_ROAD:
                        css += ' board-space-tile--road';
                        break;
                    case TileType_1.TileType.MOON_MINE:
                        css += ' board-space-tile--mine';
                        break;
                    default:
                        const cssClass = tileTypeToCssClass.get(tileType);
                        css += ' board-space-tile--' + cssClass;
                }
            }
            if (this.tileView === 'hide') {
                css += ' board-hidden-tile';
            }
            return css;
        },
    },
});
