"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalParameter = void 0;
var GlobalParameter;
(function (GlobalParameter) {
    GlobalParameter[GlobalParameter["OCEANS"] = 0] = "OCEANS";
    GlobalParameter[GlobalParameter["OXYGEN"] = 1] = "OXYGEN";
    GlobalParameter[GlobalParameter["TEMPERATURE"] = 2] = "TEMPERATURE";
    GlobalParameter[GlobalParameter["VENUS"] = 3] = "VENUS";
    GlobalParameter[GlobalParameter["MOON_HABITAT_RATE"] = 4] = "MOON_HABITAT_RATE";
    GlobalParameter[GlobalParameter["MOON_MINING_RATE"] = 5] = "MOON_MINING_RATE";
    GlobalParameter[GlobalParameter["MOON_LOGISTICS_RATE"] = 6] = "MOON_LOGISTICS_RATE";
})(GlobalParameter = exports.GlobalParameter || (exports.GlobalParameter = {}));
