"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'CardParty',
    props: {
        party: {
            required: true,
            type: String,
        },
        size: {
            required: false,
            default: 'card',
            type: String,
        },
    },
    methods: {
        getClasses() {
            const p = this.party.toLowerCase().replace(' ', '-');
            switch (this.size) {
                case 'card':
                    return 'card-description card-party--' + p;
                case 'req':
                    return 'card-party--' + p + '-req';
            }
            return '';
        },
    },
});
