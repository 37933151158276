"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const paths = require("@/common/app/paths");
exports.default = vue_1.default.extend({
    name: 'admin-home',
    data() {
        return {
            paths: [
                paths.API_STATS,
                paths.GAMES_OVERVIEW,
                paths.API_GAMES,
                paths.API_METRICS,
                paths.LOAD,
            ],
        };
    },
    computed: {
        serverId() {
            return new URLSearchParams(window.location.search).get('serverId') || '';
        },
    },
});
