"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const ICardRenderDescription_1 = require("@/common/cards/render/ICardRenderDescription");
const Types_1 = require("@/common/cards/render/Types");
const CardRenderItemComponent_vue_1 = require("@/client/components/card/CardRenderItemComponent.vue");
const CardProductionBoxComponent_vue_1 = require("@/client/components/card/CardProductionBoxComponent.vue");
const CardRenderEffectBoxComponent_vue_1 = require("@/client/components/card/CardRenderEffectBoxComponent.vue");
const CardRenderCorpBoxComponent_vue_1 = require("@/client/components/card/CardRenderCorpBoxComponent.vue");
const CardRenderTileComponent_vue_1 = require("@/client/components/card/CardRenderTileComponent.vue");
const CardDescription_vue_1 = require("@/client/components/card/CardDescription.vue");
const CardRenderSymbolComponent_vue_1 = require("@/client/components/card/CardRenderSymbolComponent.vue");
exports.default = vue_1.default.extend({
    name: 'CardRowComponent',
    props: {
        componentData: {
            type: Object,
            required: true,
        },
    },
    components: {
        CardRenderSymbolComponent: CardRenderSymbolComponent_vue_1.default,
        CardRenderItemComponent: CardRenderItemComponent_vue_1.default,
        CardProductionBoxComponent: CardProductionBoxComponent_vue_1.default,
        CardRenderEffectBoxComponent: CardRenderEffectBoxComponent_vue_1.default,
        CardRenderCorpBoxComponent: CardRenderCorpBoxComponent_vue_1.default,
        CardRenderTileComponent: CardRenderTileComponent_vue_1.default,
        CardDescription: CardDescription_vue_1.default,
    },
    methods: {
        isItem: Types_1.isICardRenderItem,
        isSymbol: Types_1.isICardRenderSymbol,
        isEffect: Types_1.isICardRenderEffect,
        isDescription(componentData) {
            return typeof componentData === 'string' || componentData instanceof String || (0, ICardRenderDescription_1.isIDescription)(this.componentData);
        },
        isTile: Types_1.isICardRenderTile,
        isProduction: Types_1.isICardRenderProductionBox,
        isCorpBoxEffect: Types_1.isICardRenderCorpBoxEffect,
        isCorpBoxAction: Types_1.isICardRenderCorpBoxAction,
        isCorpBox(item) {
            return this.isCorpBoxEffect(item) || this.isCorpBoxAction(item);
        },
        corpBoxLabel() {
            if (this.isCorpBoxEffect(this.componentData)) {
                return 'effect';
            }
            else if (this.isCorpBoxAction(this.componentData)) {
                return 'action';
            }
            return 'n/a';
        },
    },
});
