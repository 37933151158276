"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const chart_js_1 = require("chart.js");
const Color_1 = require("@/common/Color");
chart_js_1.Chart.register(...chart_js_1.registerables);
chart_js_1.Chart.defaults.font.size = 20;
chart_js_1.Chart.defaults.font.family = 'Ubuntu, Sans';
chart_js_1.Chart.defaults.color = 'rgb(240, 240, 240)';
const ColorStringMap = {
    [Color_1.Color.RED]: 'rgb(153, 17, 0)',
    [Color_1.Color.YELLOW]: 'rgb(170, 170, 0)',
    [Color_1.Color.GREEN]: 'rgb(0, 153, 0)',
    [Color_1.Color.BLACK]: 'rgb(170, 170, 170)',
    [Color_1.Color.BLUE]: 'rgb(0, 102, 255)',
    [Color_1.Color.PURPLE]: 'rgb(140, 0, 255)',
    [Color_1.Color.ORANGE]: 'rgb(236, 113, 12)',
    [Color_1.Color.PINK]: 'rgb(245, 116, 187)',
    [Color_1.Color.NEUTRAL]: '',
    [Color_1.Color.BRONZE]: '',
};
exports.default = vue_1.default.extend({
    name: 'VictoryPointChart',
    data: function () {
        return {};
    },
    props: {
        players: {
            type: Array,
        },
        generation: {
            type: Number,
        },
        animation: {
            type: Boolean,
        },
    },
    methods: {
        getLabels: function () {
            return Array.from({ length: this.generation }, (_, index) => index + 1);
        },
        getOnePlayerDataSet: function (player) {
            return {
                label: player.name,
                data: player.victoryPointsByGeneration,
                fill: false,
                backgroundColor: ColorStringMap[player.color],
                borderColor: ColorStringMap[player.color],
                tension: 0.1,
                pointRadius: 6,
            };
        },
        getAllPlayerDataSet: function () {
            return this.players.map((player) => this.getOnePlayerDataSet(player));
        },
        renderChart: function () {
            const ctx = document.getElementById('victory-point-chart');
            if (ctx !== null) {
                new chart_js_1.Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: this.getLabels(),
                        datasets: this.getAllPlayerDataSet(),
                    },
                    options: {
                        animation: {
                            duration: this.animation ? 1000 : 0,
                            easing: 'linear',
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                title: { text: 'Victory Points', display: true },
                                grid: {
                                    color: (ctx) => {
                                        return ctx.tick.value % 10 === 0 ? 'lightgray' : 'rgb(90, 90, 90)';
                                    },
                                },
                                beginAtZero: true,
                                ticks: {
                                    autoSkip: false,
                                    stepSize: 5,
                                    callback: (value) => {
                                        if (typeof (value) === 'string')
                                            return value;
                                        return value % 10 === 0 ? value : '';
                                    },
                                },
                            },
                            x: {
                                title: { text: 'Generation', display: true },
                                offset: true,
                            },
                        },
                    },
                });
            }
        },
    },
    mounted() {
        this.renderChart();
    },
});
