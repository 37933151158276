"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Button_vue_1 = require("@/client/components/common/Button.vue");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const CardOrderStorage_1 = require("@/client/utils/CardOrderStorage");
const PaymentWidgetMixin_1 = require("@/client/mixins/PaymentWidgetMixin");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const Tag_1 = require("@/common/cards/Tag");
const Units_1 = require("@/common/Units");
const CardName_1 = require("@/common/cards/CardName");
exports.default = vue_1.default.extend({
    name: 'SelectProjectCardToPlay',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    computed: {
        thisPlayer() {
            return this.playerView.thisPlayer;
        },
    },
    data() {
        let card;
        let cards = [];
        if (this.playerinput !== undefined &&
            this.playerinput.cards !== undefined &&
            this.playerinput.cards.length > 0) {
            cards = CardOrderStorage_1.CardOrderStorage.getOrdered(CardOrderStorage_1.CardOrderStorage.getCardOrder(this.playerView.id), this.playerinput.cards);
            card = cards[0];
        }
        if (card === undefined) {
            throw new Error('no card provided in player input');
        }
        return {
            cardName: card.name,
            card: card,
            cards: cards,
            cost: 0,
            tags: [],
            heat: 0,
            megaCredits: 0,
            steel: 0,
            titanium: 0,
            microbes: 0,
            science: 0,
            seeds: 0,
            data: 0,
            floaters: 0,
            warning: undefined,
            available: Units_1.Units.of({}),
        };
    },
    components: {
        Card: Card_vue_1.default,
        Button: Button_vue_1.default,
    },
    mounted() {
        vue_1.default.nextTick(() => {
            this.$data.card = this.getCard();
            this.$data.cost = this.$data.card.calculatedCost;
            this.$data.tags = this.getCardTags(),
                this.$data.megaCredits = this.getMegaCreditsMax();
            this.setDefaultValues();
        });
    },
    methods: Object.assign(Object.assign({}, PaymentWidgetMixin_1.PaymentWidgetMixin.methods), { getCard() {
            const card = this.cards.find((c) => c.name === this.cardName);
            if (card === undefined) {
                throw new Error(`card not found ${this.cardName}`);
            }
            return card;
        },
        getCardTags() {
            return (0, ClientCardManifest_1.getCardOrThrow)(this.cardName).tags;
        },
        setDefaultValues() {
            this.microbes = 0;
            this.floaters = 0;
            this.science = 0;
            this.seeds = 0;
            this.steel = 0;
            this.titanium = 0;
            this.heat = 0;
            let megacreditBalance = Math.max(this.cost - this.thisPlayer.megaCredits, 0);
            const deductUnits = function (availableUnits, unitValue, overpay = true) {
                if (availableUnits === undefined || availableUnits === 0) {
                    return 0;
                }
                const _tmp = megacreditBalance / unitValue;
                const balanceAsUnits = overpay ? Math.ceil(_tmp) : Math.floor(_tmp);
                const contributingUnits = Math.min(availableUnits, balanceAsUnits);
                megacreditBalance -= contributingUnits * unitValue;
                return contributingUnits;
            };
            const saveOverSpendingUnits = function (spendingUnits, unitValue) {
                if (spendingUnits === undefined || spendingUnits === 0 || megacreditBalance === 0) {
                    return 0;
                }
                const overSpendingAsUnits = Math.floor(Math.abs(megacreditBalance) / unitValue);
                const toSaveUnits = Math.min(spendingUnits, overSpendingAsUnits);
                megacreditBalance += toSaveUnits * unitValue;
                return toSaveUnits;
            };
            if (megacreditBalance > 0 && this.canUseSeeds()) {
                this.seeds = deductUnits(this.playerinput.seeds, 5);
            }
            if (megacreditBalance > 0 && this.canUseMicrobes()) {
                this.microbes = deductUnits(this.playerinput.microbes, 2);
            }
            if (megacreditBalance > 0 && this.canUseFloaters()) {
                this.floaters = deductUnits(this.playerinput.floaters, 3);
            }
            if (megacreditBalance > 0 && this.canUseScience()) {
                this.science = deductUnits(this.playerinput.science, 1);
            }
            this.available.steel = Math.max(this.thisPlayer.steel - this.card.reserveUnits.steel, 0);
            if (megacreditBalance > 0 && this.canUseSteel()) {
                this.steel = deductUnits(this.available.steel, this.thisPlayer.steelValue, true);
            }
            this.available.titanium = Math.max(this.thisPlayer.titanium - this.card.reserveUnits.titanium, 0);
            if (megacreditBalance > 0 && this.canUseTitanium()) {
                this.titanium = deductUnits(this.available.titanium, this.thisPlayer.titaniumValue, true);
            }
            if (megacreditBalance > 0 && this.canUseTitanium() === false && this.canUseLunaTradeFederationTitanium()) {
                this.titanium = deductUnits(this.available.titanium, this.thisPlayer.titaniumValue - 1, true);
            }
            this.available.heat = Math.max(this.availableHeat() - this.card.reserveUnits.heat, 0);
            if (megacreditBalance > 0 && this.canUseHeat()) {
                this.heat = deductUnits(this.available.heat, 1);
            }
            if (megacreditBalance < 0) {
                this.steel -= saveOverSpendingUnits(this.steel, this.thisPlayer.steelValue);
                this.floaters -= saveOverSpendingUnits(this.floaters, 3);
                this.microbes -= saveOverSpendingUnits(this.microbes, 2);
                this.science -= saveOverSpendingUnits(this.science, 1);
                this.seeds -= saveOverSpendingUnits(this.seeds, 5);
                this.megaCredits -= saveOverSpendingUnits(this.megaCredits, 1);
            }
        },
        canUseHeat() {
            return this.playerinput.canUseHeat === true && this.availableHeat() > 0;
        },
        canUseSteel() {
            if (this.card !== undefined && this.available.steel > 0) {
                if (this.tags.includes(Tag_1.Tag.BUILDING) || this.thisPlayer.lastCardPlayed === CardName_1.CardName.LAST_RESORT_INGENUITY) {
                    return true;
                }
            }
            return false;
        },
        canUseTitanium() {
            if (this.card !== undefined && this.available.titanium > 0) {
                if (this.tags.includes(Tag_1.Tag.SPACE) || this.thisPlayer.lastCardPlayed === CardName_1.CardName.LAST_RESORT_INGENUITY) {
                    return true;
                }
            }
            return false;
        },
        canUseLunaTradeFederationTitanium() {
            return this.card !== undefined && this.available.titanium > 0 && this.playerinput.canUseLunaTradeFederationTitanium === true;
        },
        canUseMicrobes() {
            var _a;
            if (this.card !== undefined && ((_a = this.playerinput.microbes) !== null && _a !== void 0 ? _a : 0) > 0) {
                if (this.tags.includes(Tag_1.Tag.PLANT)) {
                    return true;
                }
            }
            return false;
        },
        canUseFloaters() {
            var _a;
            if (this.card !== undefined && ((_a = this.playerinput.floaters) !== null && _a !== void 0 ? _a : 0) > 0) {
                if (this.tags.includes(Tag_1.Tag.VENUS)) {
                    return true;
                }
            }
            return false;
        },
        canUseScience() {
            var _a;
            if (this.card !== undefined && ((_a = this.playerinput.science) !== null && _a !== void 0 ? _a : 0) > 0) {
                if (this.tags.includes(Tag_1.Tag.MOON)) {
                    return true;
                }
            }
            return false;
        },
        canUseSeeds() {
            var _a;
            if (this.card !== undefined && ((_a = this.playerinput.seeds) !== null && _a !== void 0 ? _a : 0) > 0) {
                if (this.tags.includes(Tag_1.Tag.PLANT)) {
                    return true;
                }
                if (this.card.name === CardName_1.CardName.GREENERY_STANDARD_PROJECT) {
                    return true;
                }
            }
            return false;
        },
        cardChanged() {
            this.card = this.getCard();
            this.cost = this.card.calculatedCost || 0;
            this.tags = this.getCardTags();
            this.megaCredits = this.getMegaCreditsMax();
            this.setDefaultValues();
        },
        hasWarning() {
            return this.warning !== undefined;
        },
        hasCardWarning() {
            return this.card !== undefined && this.card.warning !== undefined;
        },
        showReserveSteelWarning() {
            var _a, _b;
            return ((_b = (_a = this.card) === null || _a === void 0 ? void 0 : _a.reserveUnits) === null || _b === void 0 ? void 0 : _b.steel) > 0 && this.canUseSteel();
        },
        showReserveTitaniumWarning() {
            var _a, _b;
            return ((_b = (_a = this.card) === null || _a === void 0 ? void 0 : _a.reserveUnits) === null || _b === void 0 ? void 0 : _b.titanium) > 0 && (this.canUseTitanium() || this.canUseLunaTradeFederationTitanium());
        },
        showReserveHeatWarning() {
            var _a, _b;
            return ((_b = (_a = this.card) === null || _a === void 0 ? void 0 : _a.reserveUnits) === null || _b === void 0 ? void 0 : _b.heat) > 0 && this.canUseHeat();
        },
        saveData() {
            const payment = {
                heat: this.heat,
                megaCredits: this.megaCredits,
                steel: this.steel,
                titanium: this.titanium,
                microbes: this.microbes,
                floaters: this.floaters,
                science: this.science,
                seeds: this.seeds,
                data: 0,
            };
            let totalSpent = 0;
            for (const target of PaymentWidgetMixin_1.unit) {
                if (payment[target] > this.getAmount(target)) {
                    this.$data.warning = `You do not have enough ${target}`;
                    return;
                }
                totalSpent += payment[target] * this.getResourceRate(target);
            }
            if (totalSpent < this.cost) {
                this.warning = 'Haven\'t spent enough';
                return;
            }
            if (totalSpent > this.cost) {
                const diff = totalSpent - this.cost;
                for (const target of PaymentWidgetMixin_1.unit) {
                    if (payment[target] && diff >= this.getResourceRate(target)) {
                        this.$data.warning = `You cannot overspend ${target}`;
                        return;
                    }
                }
            }
            const showAlert = (0, PreferencesManager_1.getPreferences)().show_alerts;
            if (totalSpent > this.cost && showAlert) {
                const diff = totalSpent - this.cost;
                if (confirm('Warning: You are overpaying by ' + diff + ' M€')) {
                    this.onsave({
                        type: 'projectCard',
                        card: this.card.name,
                        payment: payment,
                    });
                }
                else {
                    this.warning = 'Please adjust payment amount';
                    return;
                }
            }
            else {
                this.onsave({
                    type: 'projectCard',
                    card: this.card.name,
                    payment: payment,
                });
            }
        } }),
});
