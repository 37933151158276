"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const ColonyName_1 = require("@/common/colonies/ColonyName");
const utils_1 = require("@/common/utils/utils");
exports.default = vue_1.default.extend({
    name: 'ColonyTradeRow',
    props: {
        metadata: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ColonyName() {
            return ColonyName_1.ColonyName;
        },
    },
    methods: {
        range(n) {
            return (0, utils_1.range)(n);
        },
    },
});
