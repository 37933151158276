"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PlayerInfo_vue_1 = require("@/client/components/overview/PlayerInfo.vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'top-bar',
    props: {
        playerView: {
            type: Object,
        },
    },
    components: {
        PlayerInfo: PlayerInfo_vue_1.default,
    },
    data() {
        return {
            componentKey: 0,
        };
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        },
        toggleBar() {
            PreferencesManager_1.PreferencesManager.INSTANCE.set('hide_top_bar', this.isExpanded());
            this.forceRerender();
        },
        isExpanded() {
            return !(0, PreferencesManager_1.getPreferences)().hide_top_bar;
        },
        formatCssClass() {
            const cssClasses = ['top-bar'];
            if (!this.isExpanded()) {
                cssClasses.push('top-bar-collapsed');
            }
            return cssClasses.join(' ');
        },
    },
});
