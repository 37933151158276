"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const utils_1 = require("@/common/utils/utils");
const CardRenderItemType_1 = require("@/common/cards/render/CardRenderItemType");
const AltSecondaryTag_1 = require("@/common/cards/render/AltSecondaryTag");
const Size_1 = require("@/common/cards/render/Size");
const Types_1 = require("@/common/cards/render/Types");
const RESOURCE_AND_TAG_TYPES = [
    CardRenderItemType_1.CardRenderItemType.ANIMALS,
    CardRenderItemType_1.CardRenderItemType.PLANTS,
    CardRenderItemType_1.CardRenderItemType.MICROBES,
    CardRenderItemType_1.CardRenderItemType.SCIENCE,
    CardRenderItemType_1.CardRenderItemType.CITY
];
exports.default = vue_1.default.extend({
    name: 'CardRenderItemComponent',
    props: {
        item: {
            type: Object,
        },
    },
    methods: {
        getComponentClasses() {
            let classes = [];
            const type = this.item.type;
            if (type === CardRenderItemType_1.CardRenderItemType.TEMPERATURE) {
                classes.push('card-global-requirement');
                classes.push('card-temperature-global-requirement');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.OXYGEN) {
                classes.push('card-global-requirement');
                classes.push('card-oxygen-global-requirement');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.OCEANS) {
                classes.push('card-global-requirement');
                classes.push('card-ocean-global-requirement');
                if (this.item.size !== undefined && this.item.size !== Size_1.Size.MEDIUM) {
                    classes.push(`card-ocean--${this.item.size}`);
                }
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.VENUS) {
                classes.push('card-global-requirement');
                classes.push('card-venus-global-requirement');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.TR) {
                classes.push('card-tile');
                classes.push('card-tr');
                if (this.item.size !== undefined && this.item.size !== Size_1.Size.MEDIUM) {
                    classes.push(`card-tr--${this.item.size}`);
                }
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.TITANIUM) {
                classes.push('card-resource');
                classes.push('card-resource-titanium');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.STEEL) {
                classes.push('card-resource');
                classes.push('card-resource-steel');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.HEAT) {
                classes.push('card-resource');
                classes.push('card-resource-heat');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.ENERGY) {
                classes.push('card-resource');
                classes.push('card-resource-energy');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.PLANTS) {
                classes.push('card-resource');
                classes.push('card-resource-plant');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.MEGACREDITS) {
                classes.push('card-resource');
                classes.push('card-resource-money');
                if (this.item.size !== undefined && this.item.size !== Size_1.Size.MEDIUM) {
                    classes.push(`card-money--${this.item.size}`);
                }
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.CARDS) {
                classes.push('card-resource');
                classes.push('card-card');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.FLOATERS) {
                classes.push('card-resource');
                classes.push('card-resource-floater');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.ASTEROIDS) {
                classes.push('card-resource');
                classes.push('card-resource-asteroid');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.MICROBES) {
                classes.push('card-resource');
                classes.push('card-resource-microbe');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.ANIMALS) {
                classes.push('card-resource');
                classes.push('card-resource-animal');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.WILD) {
                classes.push('card-resource');
                classes.push('card-resource-wild');
                if (this.item.cancelled === true)
                    classes.push('card-private-security');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.PRESERVATION) {
                classes.push('card-resource');
                classes.push('card-resource-preservation');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.FIGHTER) {
                classes.push('card-resource');
                classes.push('card-resource-fighter');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.CAMPS) {
                classes.push('card-resource');
                classes.push('card-resource-camp');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.DIVERSE_TAG) {
                classes.push('card-resource');
                classes.push('card-resource-diverse');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.SCIENCE) {
                classes.push('card-resource');
                classes.push('card-resource-science');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.TRADE) {
                classes.push('card-resource-trade');
                if (this.item.size === Size_1.Size.SMALL) {
                    classes.push('card-resource-colony--S');
                }
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.COLONIES) {
                classes.push('card-resource-colony');
                if (this.item.size === Size_1.Size.SMALL) {
                    classes.push('card-resource-colony--S');
                }
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.TRADE_DISCOUNT || type === CardRenderItemType_1.CardRenderItemType.MULTIPLIER_WHITE) {
                classes.push('card-resource');
                classes.push('card-resource-trade-discount');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.TRADE_FLEET) {
                classes.push('card-resource-trade-fleet');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.SYNDICATE_FLEET) {
                classes.push('card-resource');
                classes.push('card-resource-syndicate-fleet');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.CHAIRMAN) {
                classes.push('card-chairman');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.PARTY_LEADERS) {
                classes.push('card-party-leader');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.DELEGATES) {
                classes.push('card-delegate');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.INFLUENCE) {
                classes.push('card-influence');
                classes.push(`card-influence--size-${this.item.size}`);
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.NO_TAGS) {
                classes.push('card-resource-tag');
                classes.push('card-community-services');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.CITY) {
                classes.push('card-tile');
                classes.push(`city-tile--${this.item.size}`);
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.GREENERY) {
                classes.push('card-tile');
                if (this.item.secondaryTag === AltSecondaryTag_1.AltSecondaryTag.OXYGEN) {
                    classes.push(`greenery-tile-oxygen--${this.item.size}`);
                }
                else {
                    classes.push(`greenery-tile--${this.item.size}`);
                }
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.EMPTY_TILE) {
                classes.push('card-tile-ares');
                if (this.item.size !== undefined) {
                    classes.push(`board-space-tile--empty-tile--${this.item.size}`);
                }
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.EMPTY_TILE_GOLDEN) {
                classes.push('card-tile-ares');
                classes.push('board-space-tile--adjacency-tile');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.EMPTY_TILE_SPECIAL) {
                classes.push('card-tile');
                if (this.item.size !== undefined) {
                    classes.push(`special-tile--${this.item.size}`);
                }
                else {
                    classes.push('special-tile');
                }
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.COMMUNITY) {
                classes.push('card-resource');
                classes.push('card-resource-community');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.DISEASE) {
                classes.push('card-resource');
                classes.push('card-resource-disease');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.DATA_RESOURCE) {
                classes.push('card-resource');
                classes.push('card-resource-data');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.RESOURCE_CUBE) {
                classes.push('card-resource');
                classes.push('card-resource-cube');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.VENUSIAN_HABITAT) {
                classes.push('card-resource');
                classes.push('card-resource-venusian-habitat');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.SPECIALIZED_ROBOT) {
                classes.push('card-resource');
                classes.push('card-resource-specialized-robot');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.SEED) {
                classes.push('card-resource');
                classes.push('card-resource-seed');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.ORBITAL) {
                classes.push('card-resource');
                classes.push('card-resource-orbital');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.AGENDA) {
                classes.push('card-resource');
                classes.push('card-resource-agenda');
            }
            else if (this.item.type === CardRenderItemType_1.CardRenderItemType.MOON_HABITAT) {
                if (this.item.secondaryTag === AltSecondaryTag_1.AltSecondaryTag.MOON_HABITAT_RATE) {
                    classes.push(sized('card-tile-lunar-colony-rate', this.item.size));
                }
                else {
                    classes.push(sized('card-tile-lunar-colony', this.item.size));
                }
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.GLOBAL_EVENT) {
                classes.push('turmoil-global-event');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.ARROW_OPG) {
                classes.push('card-arrow-opg');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.REDS) {
                classes.push('card-reds');
            }
            else if (type === CardRenderItemType_1.CardRenderItemType.REDS_DEACTIVATED) {
                classes.push('card-reds-deactivated');
            }
            function sized(clazz, size) {
                return size !== undefined ? `${clazz}--${size}` : clazz;
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.MOON_HABITAT_RATE) {
                classes.push('card-colony-rate');
                if (this.item.size !== undefined)
                    classes.push(`card-colony-rate--${this.item.size}`);
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.MOON_MINE) {
                if (this.item.secondaryTag === AltSecondaryTag_1.AltSecondaryTag.MOON_MINING_RATE) {
                    classes.push(sized('card-tile-lunar-mine-rate', this.item.size));
                }
                else {
                    classes.push(sized('card-tile-lunar-mine', this.item.size));
                }
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.MOON_MINING_RATE) {
                classes.push('card-mining-rate');
                if (this.item.size !== undefined)
                    classes.push(`card-mining-rate--${this.item.size}`);
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.MOON_ROAD) {
                if (this.item.secondaryTag === AltSecondaryTag_1.AltSecondaryTag.MOON_LOGISTICS_RATE) {
                    classes.push(sized('card-tile-lunar-road-rate', this.item.size));
                }
                else {
                    classes.push(sized('card-tile-lunar-road', this.item.size));
                }
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.MOON_LOGISTICS_RATE) {
                classes.push('card-logistics-rate');
                if (this.item.size !== undefined)
                    classes.push(`card-logistics-rate--${this.item.size}`);
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.PLANETARY_TRACK) {
                classes.push('card-planetary-track');
            }
            if (this.item.secondaryTag === AltSecondaryTag_1.AltSecondaryTag.NO_PLANETARY_TAG) {
                classes.push('tag-clone');
            }
            if (this.item.isPlayed) {
                if (RESOURCE_AND_TAG_TYPES.includes(type)) {
                    classes = classes.filter((c) => c !== 'card-resource');
                }
                classes.push('card-resource-tag');
                if (type === CardRenderItemType_1.CardRenderItemType.EVENT) {
                    classes.push('card-tag-event');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.SPACE) {
                    classes.push('card-tag-space');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.SCIENCE) {
                    classes.push('card-tag-science');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.JOVIAN) {
                    classes.push('card-tag-jovian');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.VENUS) {
                    classes.push('card-tag-venus');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.EARTH) {
                    classes.push('card-tag-earth');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.BUILDING) {
                    classes.push('card-tag-building');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.CITY) {
                    classes.push('card-tag tag-city');
                }
                else if (this.item.type === CardRenderItemType_1.CardRenderItemType.MARS) {
                    classes.push('card-tag tag-mars');
                }
            }
            if (this.item.anyPlayer === true) {
                if (type === CardRenderItemType_1.CardRenderItemType.DELEGATES) {
                    classes.push('card-delegate-red');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.CHAIRMAN) {
                    classes.push('card-chairman-red');
                }
                else {
                    classes.push('red-outline');
                }
            }
            if (this.item.isPlate) {
                classes.push('card-plate');
            }
            if (this.item.text !== undefined) {
                classes.push(`card-text-size--${this.item.size}`);
                if (this.item.isUppercase) {
                    classes.push('card-text-uppercase');
                }
                if (this.item.isBold) {
                    classes.push('card-text-bold');
                }
                else {
                    classes.push('card-text-normal');
                }
            }
            return (0, utils_1.generateClassString)(classes);
        },
        getAmountAbs() {
            if (this.item.amountInside)
                return 1;
            return Math.abs(this.item.amount);
        },
        itemsToShow() {
            if (this.item.showDigit)
                return 1;
            return this.getAmountAbs();
        },
        itemHtmlContent() {
            let result = '';
            if ((0, Types_1.isICardRenderItem)(this.item) && this.item.amountInside) {
                if (this.item.amount !== 0) {
                    result += this.item.amount.toString();
                }
                if (this.item.multiplier) {
                    result += 'X';
                }
            }
            const previouslyRendered = [
                AltSecondaryTag_1.AltSecondaryTag.OXYGEN,
                AltSecondaryTag_1.AltSecondaryTag.MOON_HABITAT_RATE,
                AltSecondaryTag_1.AltSecondaryTag.MOON_MINING_RATE,
                AltSecondaryTag_1.AltSecondaryTag.MOON_LOGISTICS_RATE,
            ];
            const secondaryTag = this.item.secondaryTag;
            if (secondaryTag !== undefined && !previouslyRendered.includes(secondaryTag)) {
                result += '<div class="card-icon card-tag-' + secondaryTag + '"></div>';
            }
            if (this.item.isPlate || this.item.text !== undefined) {
                result += this.item.text || 'n/a';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.NO_TAGS || this.item.type === CardRenderItemType_1.CardRenderItemType.MULTIPLIER_WHITE) {
                result = 'X';
            }
            else if (this.item.type === CardRenderItemType_1.CardRenderItemType.IGNORE_GLOBAL_REQUIREMENTS) {
                result += '<div class="card-project-requirements">';
                result += '<div class="card-x">x</div>';
                result += '<div class="card-requirements">Global Requirements</div>';
                result += '</div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.SELF_REPLICATING) {
                result = '<div class="card-resource card-card"><div class="cards-count">2</div><div class="card-icon card-icon-space">✴</div><div class="card-icon card-icon-building">☗</div></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.PLACE_COLONY) {
                result = '<span class="card-place-colony">colony</span>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.PRELUDE) {
                result = '<div class="card-prelude-container"><span class="card-prelude-icon">prel</span></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.CORPORATION) {
                result = '<div class="card-corporation-icon"></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.FIRST_PLAYER) {
                result = '<div class="card-first-player-icon"></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.RULING_PARTY) {
                result = '<div class="card-party-icon"></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.AWARD) {
                result = '<span class="card-award-icon">award</span>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.VP) {
                result = '<div class="card-resource points-big card-vp-questionmark">?</div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.MEGACREDITS && this.item.amount === 1000) {
                result = '?';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.MOON) {
                return '<div class="card-tag-moon-on-card"></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.RESOURCE_CUBE) {
                return '<div class="board-cube--bronze"></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.TR && this.item.cancelled === true) {
                result = '<div class="card-x">x</div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.WILD && this.item.cancelled === true) {
                result = '<div class="card-x">✕</div>';
            }
            return result;
        },
    },
});
