"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardRenderItemComponent_vue_1 = require("@/client/components/card/CardRenderItemComponent.vue");
const CardRenderEffectBoxComponent_vue_1 = require("@/client/components/card/CardRenderEffectBoxComponent.vue");
const CardRenderSymbolComponent_vue_1 = require("@/client/components/card/CardRenderSymbolComponent.vue");
exports.default = vue_1.default.extend({
    name: 'CardRenderCorpBoxComponent',
    props: {
        rows: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    components: {
        CardRenderSymbolComponent: CardRenderSymbolComponent_vue_1.default,
        CardRenderItemComponent: CardRenderItemComponent_vue_1.default,
        CardRenderEffectBoxComponent: CardRenderEffectBoxComponent_vue_1.default,
    },
    methods: {
        getClasses() {
            const classes = ['card-corporation-box'];
            return classes.join(' ');
        },
    },
});
