"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const MilestoneAwardManifest_1 = require("@/client/MilestoneAwardManifest");
exports.default = vue_1.default.extend({
    name: 'Award',
    props: {
        award: {
            type: Object,
            required: true,
        },
        showScores: {
            type: Boolean,
            default: false,
        },
        showDescription: {
            type: Boolean,
        },
    },
    data() {
        return {};
    },
    computed: {
        maAwardClass() {
            return 'ma-name--' + this.award.name.replace(/ /g, '-').replace(/\./g, '').toLowerCase();
        },
        sortedScores() {
            return [...this.award.scores].sort((s1, s2) => s2.playerScore - s1.playerScore);
        },
        description() {
            return (0, MilestoneAwardManifest_1.getMilestoneAwardDescription)(this.award.name);
        },
    },
});
