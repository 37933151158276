"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardTitle_vue_1 = require("./CardTitle.vue");
const CardResourceCounter_vue_1 = require("./CardResourceCounter.vue");
const CardCost_vue_1 = require("./CardCost.vue");
const CardExtraContent_vue_1 = require("./CardExtraContent.vue");
const CardExpansion_vue_1 = require("./CardExpansion.vue");
const CardTags_vue_1 = require("./CardTags.vue");
const CardType_1 = require("@/common/cards/CardType");
const CardContent_vue_1 = require("./CardContent.vue");
const CardHelp_vue_1 = require("./CardHelp.vue");
const Tag_1 = require("@/common/cards/Tag");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const CardResource_1 = require("@/common/CardResource");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const CardName_1 = require("@/common/cards/CardName");
const names = [
    CardName_1.CardName.BOTANICAL_EXPERIENCE,
    CardName_1.CardName.MARS_DIRECT,
    CardName_1.CardName.LUNA_ECUMENOPOLIS,
    CardName_1.CardName.ROBOTIC_WORKFORCE,
];
exports.default = vue_1.default.extend({
    name: 'Card',
    components: {
        CardTitle: CardTitle_vue_1.default,
        CardHelp: CardHelp_vue_1.default,
        CardResourceCounter: CardResourceCounter_vue_1.default,
        CardCost: CardCost_vue_1.default,
        CardExtraContent: CardExtraContent_vue_1.default,
        CardExpansion: CardExpansion_vue_1.default,
        CardTags: CardTags_vue_1.default,
        CardContent: CardContent_vue_1.default,
    },
    props: {
        card: {
            type: Object,
            required: true,
        },
        actionUsed: {
            type: Boolean,
            required: false,
            default: false,
        },
        robotCard: {
            type: Object,
            required: false,
        },
    },
    data() {
        const cardName = this.card.name;
        const card = (0, ClientCardManifest_1.getCardOrThrow)(cardName);
        return {
            cardInstance: card,
        };
    },
    methods: {
        getCardExpansion() {
            return this.cardInstance.module;
        },
        getTags() {
            const type = this.getCardType();
            const tags = [...this.cardInstance.tags || []];
            tags.forEach((tag, idx) => {
                if (tag === Tag_1.Tag.CLONE && this.card.cloneTag !== undefined) {
                    tags[idx] = this.card.cloneTag;
                }
            });
            if (type === CardType_1.CardType.EVENT) {
                tags.push(Tag_1.Tag.EVENT);
            }
            return tags;
        },
        getCost() {
            return this.isProjectCard() ? this.cardInstance.cost : undefined;
        },
        getReducedCost() {
            return this.isProjectCard() ? this.card.calculatedCost : undefined;
        },
        getCardType() {
            return this.cardInstance.cardType;
        },
        getCardClasses(card) {
            const classes = ['card-container', 'filterDiv', 'hover-hide-res'];
            classes.push('card-' + card.name.toLowerCase().replace(/ /g, '-'));
            if (this.actionUsed || card.isDisabled) {
                classes.push('card-unavailable');
            }
            if (this.isStandardProject()) {
                classes.push('card-standard-project');
            }
            const learnerModeOff = !(0, PreferencesManager_1.getPreferences)().learner_mode;
            if (learnerModeOff && this.isStandardProject() && card.isDisabled) {
                classes.push('card-hide');
            }
            return classes.join(' ');
        },
        getCardMetadata() {
            return this.cardInstance.metadata;
        },
        getCardRequirements() {
            return this.cardInstance.requirements;
        },
        getResourceAmount() {
            var _a;
            return this.card.resources || ((_a = this.robotCard) === null || _a === void 0 ? void 0 : _a.resources) || 0;
        },
        isCorporationCard() {
            return this.getCardType() === CardType_1.CardType.CORPORATION;
        },
        isProjectCard() {
            const type = this.getCardType();
            return type !== CardType_1.CardType.PRELUDE && type !== CardType_1.CardType.CORPORATION && type !== CardType_1.CardType.CEO;
        },
        isStandardProject() {
            return this.getCardType() === CardType_1.CardType.STANDARD_PROJECT || this.getCardType() === CardType_1.CardType.STANDARD_ACTION;
        },
    },
    computed: {
        hasResourceType() {
            return this.card.isSelfReplicatingRobotsCard === true || this.cardInstance.resourceType !== undefined || this.robotCard !== undefined;
        },
        resourceType() {
            var _a;
            if (this.robotCard !== undefined || this.card.isSelfReplicatingRobotsCard === true)
                return CardResource_1.CardResource.RESOURCE_CUBE;
            return (_a = this.cardInstance.resourceType) !== null && _a !== void 0 ? _a : CardResource_1.CardResource.RESOURCE_CUBE;
        },
        hasHelp() {
            return names.includes(this.card.name) && (0, PreferencesManager_1.getPreferences)().experimental_ui;
        },
    },
});
