"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const PreferencesDialog_vue_1 = require("@/client/components/PreferencesDialog.vue");
exports.default = vue_1.default.extend({
    name: 'PreferencesIcon',
    components: {
        'preferences-dialog': PreferencesDialog_vue_1.default,
    },
    data() {
        return {
            preferencesPanelOpen: false,
        };
    },
    computed: {
        preferencesManager() {
            return PreferencesManager_1.PreferencesManager.INSTANCE;
        },
    },
});
