"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardRenderItemComponent_vue_1 = require("@/client/components/card/CardRenderItemComponent.vue");
const CardRenderSymbolComponent_vue_1 = require("@/client/components/card/CardRenderSymbolComponent.vue");
const Types_1 = require("@/common/cards/render/Types");
const CardProductionBoxComponent_vue_1 = require("@/client/components/card/CardProductionBoxComponent.vue");
const CardRenderTileComponent_vue_1 = require("@/client/components/card/CardRenderTileComponent.vue");
const Types_2 = require("@/common/cards/render/Types");
const CardDescription_vue_1 = require("@/client/components/card/CardDescription.vue");
exports.default = vue_1.default.extend({
    name: 'CardRenderEffectBoxComponent',
    props: {
        effectData: {
            type: Object,
            required: true,
        },
    },
    components: {
        CardRenderItemComponent: CardRenderItemComponent_vue_1.default,
        CardRenderSymbolComponent: CardRenderSymbolComponent_vue_1.default,
        CardProductionBoxComponent: CardProductionBoxComponent_vue_1.default,
        CardRenderTileComponent: CardRenderTileComponent_vue_1.default,
        CardDescription: CardDescription_vue_1.default,
    },
    methods: {
        getClasses() {
            return 'card-effect-box';
        },
        isItem: Types_1.isICardRenderItem,
        isSymbol: Types_2.isICardRenderSymbol,
        isProductionBox: Types_1.isICardRenderProductionBox,
        isTile: Types_2.isICardRenderTile,
    },
    computed: {
        cause() {
            return this.effectData.rows[0];
        },
        delimiter() {
            var _a;
            if (((_a = this.cause) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                return undefined;
            }
            return this.effectData.rows[1][0];
        },
        effect() {
            return this.effectData.rows[2];
        },
        description() {
            return this.effectData.rows[2].slice(-1)[0];
        },
    },
});
