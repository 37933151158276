"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Tag_1 = require("@/common/cards/Tag");
const utils_1 = require("@/common/utils/utils");
const PlanetaryTrack_vue_1 = require("@/client/components/pathfinders/PlanetaryTrack.vue");
const PlanetaryTracks_1 = require("@/common/pathfinders/PlanetaryTracks");
exports.default = vue_1.default.extend({
    name: 'PlanetaryTracks',
    props: {
        tracks: {
            type: Object,
        },
        gameOptions: {
            type: Object,
        },
    },
    components: {
        PlanetaryTrack: PlanetaryTrack_vue_1.default,
    },
    data() {
        return {
            venus: Tag_1.Tag.VENUS,
            earth: Tag_1.Tag.EARTH,
            mars: Tag_1.Tag.MARS,
            jovian: Tag_1.Tag.JOVIAN,
            moon: Tag_1.Tag.MOON,
            range: (0, utils_1.range)(23),
            rewards: PlanetaryTracks_1.PlanetaryTracks.initialize(),
        };
    },
});
