"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants_1 = require("@/common/constants");
const Milestone_vue_1 = require("@/client/components/Milestone.vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'Milestones',
    props: {
        milestones: {
            type: Array,
        },
        showScores: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showMilestones: this.milestones.filter((milestone) => milestone.playerName).length === constants_1.MAX_MILESTONES ? false : true,
            showDescription: false,
        };
    },
    components: {
        Milestone: Milestone_vue_1.default,
    },
    methods: {
        toggleDescription() {
            this.showDescription = !this.showDescription;
        },
        toggleList() {
            this.showMilestones = !this.showMilestones;
        },
        getAvailableMilestoneSpots() {
            const count = this.milestones.filter((milestone) => milestone.playerName).length;
            return Array(constants_1.MAX_MILESTONES - count).fill(constants_1.MILESTONE_COST);
        },
        isLearnerModeOn() {
            return (0, PreferencesManager_1.getPreferences)().learner_mode;
        },
    },
});
