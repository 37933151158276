"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardRequirementsComponent_vue_1 = require("./CardRequirementsComponent.vue");
const CardVictoryPoints_vue_1 = require("./CardVictoryPoints.vue");
const CardDescription_vue_1 = require("./CardDescription.vue");
const CardRenderData_vue_1 = require("./CardRenderData.vue");
exports.default = vue_1.default.extend({
    name: 'CardContent',
    props: {
        metadata: {
            type: Object,
            required: true,
        },
        requirements: {
            type: Object,
        },
        isCorporation: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        CardRequirementsComponent: CardRequirementsComponent_vue_1.default,
        CardVictoryPoints: CardVictoryPoints_vue_1.default,
        CardDescription: CardDescription_vue_1.default,
        CardRenderData: CardRenderData_vue_1.default,
    },
    methods: {
        getClasses() {
            const classes = ['card-content'];
            if (this.isCorporation) {
                classes.push('card-content-corporation');
            }
            return classes.join(' ');
        },
    },
});
