"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const HelpIconology_vue_1 = require("@/client/components/help/HelpIconology.vue");
const HelpPhases_vue_1 = require("@/client/components/help/HelpPhases.vue");
const HelpStandardProjects_vue_1 = require("@/client/components/help/HelpStandardProjects.vue");
exports.default = vue_1.default.extend({
    name: 'Help',
    data() {
        return {
            currentPage: 'iconology',
        };
    },
    components: {
        HelpIconology: HelpIconology_vue_1.default,
        HelpStandardProjects: HelpStandardProjects_vue_1.default,
        HelpPhases: HelpPhases_vue_1.default,
    },
    methods: {
        setTab(tab) {
            this.currentPage = tab;
        },
        isOpen(tab) {
            return tab === this.currentPage;
        },
    },
});
