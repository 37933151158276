"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Button_vue_1 = require("@/client/components/common/Button.vue");
const Party_vue_1 = require("@/client/components/Party.vue");
exports.default = vue_1.default.extend({
    name: 'SelectPartyToSendDelegate',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        return {
            selectedParty: undefined,
        };
    },
    components: { Button: Button_vue_1.default, Party: Party_vue_1.default },
    methods: {
        saveData() {
            this.onsave({ type: 'party', partyName: this.selectedParty });
        },
        isDominant(partyName) {
            var _a;
            return partyName === ((_a = this.playerinput.turmoil) === null || _a === void 0 ? void 0 : _a.dominant);
        },
        partyAvailableToSelect(partyName) {
            if (this.playerinput.availableParties === undefined) {
                return false;
            }
            else {
                return this.playerinput.availableParties.includes(partyName);
            }
        },
    },
});
