"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Button_vue_1 = require("@/client/components/common/Button.vue");
const SelectPlayerRow_vue_1 = require("@/client/components/SelectPlayerRow.vue");
exports.default = vue_1.default.extend({
    name: 'SelectPlayer',
    props: {
        players: {
            type: Array,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        return {
            selectedPlayer: undefined,
        };
    },
    components: {
        SelectPlayerRow: SelectPlayerRow_vue_1.default,
        Button: Button_vue_1.default,
    },
    methods: {
        saveData() {
            this.onsave({ type: 'player', player: this.selectedPlayer });
        },
    },
});
