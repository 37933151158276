"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vueRoot_1 = require("@/client/components/vueRoot");
exports.default = vue_1.default.extend({
    name: 'OverviewSettings',
    methods: {
        toggleTagsView() {
            (0, vueRoot_1.vueRoot)(this).setVisibilityState('tags_concise', !(0, vueRoot_1.vueRoot)(this).getVisibilityState('tags_concise'));
        },
        getTagToggleLabel() {
            return (0, vueRoot_1.vueRoot)(this).componentsVisibility['tags_concise'] ? 'full' : 'concise';
        },
    },
});
