"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'PointsPerTag',
    props: {
        amount: {
            type: String,
        },
    },
    methods: {
        classes() {
            return this.amount.length === 1 ?
                'points-per-tag' :
                'points-per-tag points-per-tag--S';
        },
    },
});
