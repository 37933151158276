"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const utils_1 = require("@/common/utils/utils");
const PlanetaryTrackRewards_vue_1 = require("./PlanetaryTrackRewards.vue");
exports.default = vue_1.default.extend({
    name: 'PlanetaryTrack',
    props: {
        val: {
            type: Number,
        },
        type: String,
        rewards: {
            type: Object,
        },
        gameOptions: {
            type: Object,
        },
    },
    data() {
        return {
            range: (0, utils_1.range)(23),
        };
    },
    components: {
        PlanetaryTrackRewards: PlanetaryTrackRewards_vue_1.default,
    },
    methods: {
        getClass(idx) {
            return (idx === this.val) ? 'highlight' : '';
        },
    },
    computed: {
        icon() {
            switch (this.type) {
                case 'risingPlayer': return '^';
                case 'everyone': return '*';
                case 'mostTags': return '!';
                default: return '';
            }
        },
    },
});
