"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'PurgeWarning',
    props: {
        expectedPurgeTimeMs: {
            type: Number,
        },
    },
    computed: {
        hoursLeft() {
            const nowMs = Date.now();
            const diffMs = this.expectedPurgeTimeMs - nowMs;
            const diffh = diffMs / 3600000;
            return diffh;
        },
        purgeTime() {
            const date = new Date(this.expectedPurgeTimeMs);
            function pad(n) {
                return ('0' + n).slice(-2);
            }
            return '' + date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes());
        },
    },
});
