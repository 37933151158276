"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants_1 = require("@/common/constants");
const GlobalParameter_1 = require("@/common/GlobalParameter");
const attributes = {
    [GlobalParameter_1.GlobalParameter.TEMPERATURE]: { max: constants_1.MAX_TEMPERATURE, title: 'Temperature', iconClass: 'temperature-tile' },
    [GlobalParameter_1.GlobalParameter.OXYGEN]: { max: constants_1.MAX_OXYGEN_LEVEL, title: 'Oxygen Level', iconClass: 'oxygen-tile' },
    [GlobalParameter_1.GlobalParameter.OCEANS]: { max: constants_1.MAX_OCEAN_TILES, title: 'Oceans', iconClass: 'ocean-tile' },
    [GlobalParameter_1.GlobalParameter.VENUS]: { max: constants_1.MAX_VENUS_SCALE, title: 'Venus Scale', iconClass: 'venus-tile' },
};
exports.default = vue_1.default.extend({
    name: 'global-parameter-value',
    props: {
        param: {
            type: Number,
        },
        value: {
            type: Number,
        },
    },
    computed: {
        isMax() {
            return this.value === attributes[this.param].max;
        },
        title() {
            return attributes[this.param].title;
        },
        iconClass() {
            return attributes[this.param].iconClass;
        },
        suffix() {
            return this.param === GlobalParameter_1.GlobalParameter.OXYGEN ? '%' : '';
        },
    },
});
