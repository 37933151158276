"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const HTMLDialogElementCompatibility_1 = require("@/client/components/HTMLDialogElementCompatibility");
const raw_settings = require("@/genfiles/settings.json");
const vueRoot_1 = require("@/client/components/vueRoot");
const dialogPolyfill = require('dialog-polyfill');
function browser() {
    const ua = navigator.userAgent;
    let match = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(match[1])) {
        const temp = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (temp[1] || '');
    }
    if (match[1] === 'Chrome') {
        const temp = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (temp !== null)
            return temp.slice(1).join(' ').replace('OPR', 'Opera');
    }
    match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];
    const temp = ua.match(/version\/(\d+)/i);
    if (temp !== null)
        match.splice(1, 1, temp[1]);
    return match.join(' ');
}
exports.default = vue_1.default.extend({
    name: 'BugReportDialog',
    data() {
        return {
            message: '',
            showCopied: false,
        };
    },
    methods: {
        show() {
            (0, HTMLDialogElementCompatibility_1.showModal)(this.$refs.dialog);
        },
        copyTextArea() {
            this.$refs.textarea.select();
            navigator.clipboard.writeText(this.$refs.textarea.value);
            this.showCopied = true;
        },
        url(playerView) {
            var _a;
            const url = new URL(window.location.href);
            const spectatorId = (_a = playerView === null || playerView === void 0 ? void 0 : playerView.game) === null || _a === void 0 ? void 0 : _a.spectatorId;
            if (spectatorId && url.pathname === '/player' && url.searchParams.has('id')) {
                url.searchParams.set('id', spectatorId);
                url.pathname = '/spectator';
            }
            return url;
        },
        setMessage() {
            const playerView = (0, vueRoot_1.vueRoot)(this).playerView;
            this.message = `URL: ${this.url(playerView)}
Player color: ${playerView === null || playerView === void 0 ? void 0 : playerView.thisPlayer.color}
Step: ${playerView === null || playerView === void 0 ? void 0 : playerView.game.step}
Version: ${raw_settings.head}, built at ${raw_settings.builtAt}
Browser: ${browser()}`;
        },
    },
    mounted() {
        if (!(0, HTMLDialogElementCompatibility_1.windowHasHTMLDialogElement)())
            dialogPolyfill.default.registerDialog(this.$refs.dialog);
        this.setMessage();
    },
});
