"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const MODULE_TO_CSS = new Map([
    ['corpera', 'corporate-icon'],
    ['promo', 'promo-icon'],
    ['venus', 'venus-icon'],
    ['colonies', 'colonies-icon'],
    ['prelude', 'prelude-icon'],
    ['turmoil', 'turmoil-icon'],
    ['community', 'community-icon'],
    ['ares', 'ares-icon'],
    ['moon', 'moon-icon'],
    ['pathfinders', 'pathfinders-icon'],
    ['ceo', 'ceo-icon'],
]);
exports.default = vue_1.default.extend({
    name: 'CardExpansion',
    props: {
        expansion: {
            type: String,
            required: true,
        },
        isCorporation: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        getClasses() {
            const classes = ['card-expansion', 'project-icon'];
            const expansionClass = MODULE_TO_CSS.get(this.expansion);
            if (expansionClass !== undefined) {
                classes.push(expansionClass);
            }
            if (this.isCorporation) {
                classes.push('card-corporation-expansion');
            }
            return classes.join(' ');
        },
    },
});
