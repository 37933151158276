"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Bonus_vue_1 = require("@/client/components/Bonus.vue");
const BoardSpaceTile_vue_1 = require("@/client/components/board/BoardSpaceTile.vue");
const SpaceBonus_1 = require("@/common/boards/SpaceBonus");
exports.default = vue_1.default.extend({
    name: 'board-space',
    props: {
        space: {
            type: Object,
        },
        text: {
            type: String,
        },
        is_selectable: {
            type: Boolean,
        },
        aresExtension: {
            type: Boolean,
        },
        tileView: {
            type: String,
        },
    },
    data() {
        return {};
    },
    components: {
        'bonus': Bonus_vue_1.default,
        'board-space-tile': BoardSpaceTile_vue_1.default,
    },
    methods: {
        getMainClass() {
            let css = 'board-space board-space-' + this.space.id.toString();
            if (this.is_selectable) {
                css += ' board-space-selectable';
            }
            return css;
        },
    },
    computed: {
        showBonus() {
            return this.space.tileType === undefined || this.tileView === 'hide';
        },
        restricted() {
            return this.space.bonus.includes(SpaceBonus_1.SpaceBonus.RESTRICTED);
        },
    },
});
