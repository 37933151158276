"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const ConfirmDialog_vue_1 = require("@/client/components/common/ConfirmDialog.vue");
const GoToMap_vue_1 = require("@/client/components/waitingFor/GoToMap.vue");
exports.default = vue_1.default.extend({
    name: 'SelectSpace',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        return {
            availableSpaces: new Set(this.playerinput.availableSpaces),
            selectedTile: undefined,
            spaceId: undefined,
            warning: undefined,
        };
    },
    components: {
        'confirm-dialog': ConfirmDialog_vue_1.default,
        GoToMap: GoToMap_vue_1.default,
    },
    methods: {
        animateSpace(tile, activate) {
            if (activate) {
                tile.classList.add('board-space--available');
            }
            else {
                tile.classList.remove('board-space--available');
            }
        },
        animateAvailableSpaces(tiles) {
            tiles.forEach((tile) => {
                const spaceId = tile.getAttribute('data_space_id');
                if (spaceId !== null && this.availableSpaces.has(spaceId)) {
                    this.animateSpace(tile, true);
                }
            });
        },
        cancelPlacement() {
            if (this.selectedTile === undefined) {
                throw new Error('unexpected, no tile selected!');
            }
            this.animateSpace(this.selectedTile, false);
            this.animateAvailableSpaces(this.getSelectableSpaces());
        },
        confirmPlacement() {
            const tiles = this.getSelectableSpaces();
            tiles.forEach((tile) => {
                tile.onclick = null;
            });
            if (this.selectedTile === undefined) {
                throw new Error('unexpected, no tile selected!');
            }
            this.$data.spaceId = this.selectedTile.getAttribute('data_space_id');
            this.selectedTile.classList.add('board-space--selected');
            this.saveData();
        },
        disableAvailableSpaceAnimation() {
            const tiles = this.getSelectableSpaces();
            tiles.forEach((tile) => {
                tile.classList.remove('board-space--available', 'board-space--selected');
            });
        },
        getSelectableSpaces() {
            const spaces = [];
            let board = document.getElementById('main_board');
            if (board !== null) {
                const array = board.getElementsByClassName('board-space-selectable');
                for (let i = 0, length = array.length; i < length; i++) {
                    spaces.push(array[i]);
                }
            }
            board = document.getElementById('moon_board');
            if (board !== null) {
                const array = board.getElementsByClassName('board-space-selectable');
                for (let i = 0, length = array.length; i < length; i++) {
                    spaces.push(array[i]);
                }
            }
            return spaces;
        },
        hideDialog(hide) {
            PreferencesManager_1.PreferencesManager.INSTANCE.set('hide_tile_confirmation', hide);
        },
        onTileSelected(tile) {
            this.selectedTile = tile;
            this.disableAvailableSpaceAnimation();
            this.animateSpace(tile, true);
            tile.classList.remove('board-space--available');
            const hideTileConfirmation = (0, PreferencesManager_1.getPreferences)().hide_tile_confirmation;
            if (hideTileConfirmation) {
                this.confirmPlacement();
            }
            else {
                this.$refs.confirmation.show();
            }
        },
        experimental() {
            return (0, PreferencesManager_1.getPreferences)().experimental_ui;
        },
        saveData() {
            if (this.$data.spaceId === undefined) {
                this.$data.warning = 'Must select a space';
                return;
            }
            this.onsave({ type: 'space', spaceId: this.$data.spaceId });
        },
    },
    mounted() {
        this.disableAvailableSpaceAnimation();
        const tiles = this.getSelectableSpaces();
        this.animateAvailableSpaces(tiles);
        for (let i = 0, length = tiles.length; i < length; i++) {
            const tile = tiles[i];
            const spaceId = tile.getAttribute('data_space_id');
            if (spaceId === null || this.availableSpaces.has(spaceId) === false) {
                continue;
            }
            tile.onclick = () => this.onTileSelected(tile);
        }
    },
});
