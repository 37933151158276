"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerInputType = void 0;
var PlayerInputType;
(function (PlayerInputType) {
    PlayerInputType[PlayerInputType["AND_OPTIONS"] = 0] = "AND_OPTIONS";
    PlayerInputType[PlayerInputType["OR_OPTIONS"] = 1] = "OR_OPTIONS";
    PlayerInputType[PlayerInputType["SELECT_AMOUNT"] = 2] = "SELECT_AMOUNT";
    PlayerInputType[PlayerInputType["SELECT_CARD"] = 3] = "SELECT_CARD";
    PlayerInputType[PlayerInputType["SELECT_DELEGATE"] = 4] = "SELECT_DELEGATE";
    PlayerInputType[PlayerInputType["SELECT_PAYMENT"] = 5] = "SELECT_PAYMENT";
    PlayerInputType[PlayerInputType["SELECT_PROJECT_CARD_TO_PLAY"] = 6] = "SELECT_PROJECT_CARD_TO_PLAY";
    PlayerInputType[PlayerInputType["SELECT_INITIAL_CARDS"] = 7] = "SELECT_INITIAL_CARDS";
    PlayerInputType[PlayerInputType["SELECT_OPTION"] = 8] = "SELECT_OPTION";
    PlayerInputType[PlayerInputType["SELECT_PARTY_TO_SEND_DELEGATE"] = 9] = "SELECT_PARTY_TO_SEND_DELEGATE";
    PlayerInputType[PlayerInputType["SELECT_PLAYER"] = 10] = "SELECT_PLAYER";
    PlayerInputType[PlayerInputType["SELECT_SPACE"] = 11] = "SELECT_SPACE";
    PlayerInputType[PlayerInputType["SELECT_COLONY"] = 12] = "SELECT_COLONY";
    PlayerInputType[PlayerInputType["SELECT_PRODUCTION_TO_LOSE"] = 13] = "SELECT_PRODUCTION_TO_LOSE";
    PlayerInputType[PlayerInputType["SHIFT_ARES_GLOBAL_PARAMETERS"] = 14] = "SHIFT_ARES_GLOBAL_PARAMETERS";
})(PlayerInputType = exports.PlayerInputType || (exports.PlayerInputType = {}));
