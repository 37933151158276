"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vueRoot_1 = require("@/client/components/vueRoot");
const Awards_vue_1 = require("@/client/components/Awards.vue");
const Board_vue_1 = require("@/client/components/Board.vue");
const Colony_vue_1 = require("@/client/components/colonies/Colony.vue");
const PlanetaryTracks_vue_1 = require("@/client/components/pathfinders/PlanetaryTracks.vue");
const DynamicTitle_vue_1 = require("@/client/components/common/DynamicTitle.vue");
const LogPanel_vue_1 = require("@/client/components/LogPanel.vue");
const MoonBoard_vue_1 = require("@/client/components/moon/MoonBoard.vue");
const Milestones_vue_1 = require("@/client/components/Milestones.vue");
const Sidebar_vue_1 = require("@/client/components/Sidebar.vue");
const Turmoil_vue_1 = require("@/client/components/turmoil/Turmoil.vue");
const WaitingFor_vue_1 = require("@/client/components/WaitingFor.vue");
const PlayersOverview_vue_1 = require("@/client/components/overview/PlayersOverview.vue");
const utils_1 = require("@/common/utils/utils");
const TileView_1 = require("./board/TileView");
exports.default = vue_1.default.extend({
    name: 'SpectatorHome',
    data() {
        return {
            tileView: 'show',
            waitingForTimeout: this.settings.waitingForTimeout,
        };
    },
    props: {
        spectator: {
            type: Object,
        },
        settings: {
            type: Object,
        },
    },
    computed: {
        game() {
            return this.spectator.game;
        },
    },
    components: {
        Awards: Awards_vue_1.default,
        Board: Board_vue_1.default,
        Colony: Colony_vue_1.default,
        DynamicTitle: DynamicTitle_vue_1.default,
        LogPanel: LogPanel_vue_1.default,
        Milestone: Milestones_vue_1.default,
        MoonBoard: MoonBoard_vue_1.default,
        PlanetaryTracks: PlanetaryTracks_vue_1.default,
        PlayersOverview: PlayersOverview_vue_1.default,
        Sidebar: Sidebar_vue_1.default,
        Turmoil: Turmoil_vue_1.default,
        WaitingFor: WaitingFor_vue_1.default,
    },
    methods: {
        forceRerender() {
            (0, vueRoot_1.vueRoot)(this).updateSpectator();
        },
        range(n) {
            return (0, utils_1.range)(n);
        },
        cycleTileView() {
            this.tileView = (0, TileView_1.nextTileView)(this.tileView);
        },
    },
});
