"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardTag_vue_1 = require("@/client/components/card/CardTag.vue");
exports.default = vue_1.default.extend({
    name: 'CardTags',
    props: {
        tags: Array,
    },
    components: {
        CardTag: CardTag_vue_1.default,
    },
});
