"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OLD_BANNED_CARDS = exports.BANNED_CARDS = exports.CUSTOM_PRELUDES = exports.OLD_CUSTOM_COLONIES = exports.CUSTOM_COLONIES = exports.OLD_CUSTOM_CORPORATIONS = exports.CUSTOM_CORPORATIONS = void 0;
exports.CUSTOM_CORPORATIONS = 'customCorporations';
exports.OLD_CUSTOM_CORPORATIONS = 'customCorporationsList';
exports.CUSTOM_COLONIES = 'customColonies';
exports.OLD_CUSTOM_COLONIES = 'customColoniesList';
exports.CUSTOM_PRELUDES = 'customPreludes';
exports.BANNED_CARDS = 'bannedCards';
exports.OLD_BANNED_CARDS = 'cardsBlackList';
